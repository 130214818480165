import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Row, Col, Card, Button, Badge } from 'react-bootstrap';

import { JSONLD, Generic } from 'react-structured-data';

import config from '../../../../../config.js';
import { getFile } from '../../../../../util/file.js';

import { getPages, getContentBlockItemsByName, getContentBlockItemByName, getContentBlockMixedItem, getContentBlockItemValue, memoizedGetPages } from '../../../CmsActions.js';
import { memoizedGetProducts } from '../../../../Product/ProductActions.js';

import Icon from '../../../../../components/Content/Icon.js';
import Breadcrumbs from '../../../../App/components/view/Breadcrumbs.js';
import HtmlComponent from '../../../../../components/Text/HtmlComponent.js';
import CmsBlockItem from '../../content/CmsBlockItem.js';
import CmsPanel from '../../content/CmsPanel.js';
import PreFooter from '../../../../App/components/PreFooter.js';
import SocialShare from '../../../../../components/Social/SocialShare.js';
import ProductListItem from '../../../../Product/components/list/ProductListItem.js';
import ProductRelatedPosts from '../../../../Product/components/view/ProductRelatedPosts.js';
import PredictiveLink from '../../view/PredictiveLink.js';
import PageCover from '../../view/PageCover.js';

function PostDefault({ intl, page, blogPage = null, products = [] }) {
    const cover = getContentBlockItemValue(page.blocks, 'image', 'path');
    const title = getContentBlockItemValue(page.blocks, 'title') || page.title;
    const tags = getContentBlockItemsByName(page.blocks, 'tags');
    const contents = getContentBlockItemsByName(page.blocks, 'content');
    const breadcrumbs = getContentBlockItemsByName(page.blocks, 'breadcrumbs');
    const relatedProducts = getContentBlockItemsByName(page.blocks, 'relatedProducts');
    const relatedProductsFound = relatedProducts.map(relatedProduct => products.find(product => product._id === relatedProduct.text)).filter(product => product);

    const renderRelatedProduct = index => {
        // if(relatedProductsFound.length === 1) {
        //     return (
        //         <Row>
        //             <Col xs="12" md={{ size: 4, offset: 4 }} lg={{ size: 2, offset: 5 }}>
        //                 {relatedProductsFound.map(product => <ProductListItem key={product._id} product={product} />)}
        //             </Col>
        //         </Row>
        //     );
        // }
        // if(relatedProductsFound.length) {
        //     return (
        //         <Row>
        //             {relatedProductsFound.map(product => (
        //                 <Col key={product._id}>
        //                     <ProductListItem product={product} />
        //                 </Col>
        //             ))}
        //         </Row>
        //     );
        // }
        // return null;
        return relatedProductsFound[index] && (
            <div className="my-2">
                <ProductListItem product={relatedProductsFound[index]} />
            </div>
        );
    };

    const renderRelatedProducts = () => {
        return (
            <Container className="pt-4">
                <span className="d-block w-100 w-md-75 w-lg-50 h3 mx-auto mb-4 pb-1 text-center border-half-secondary"><FormattedMessage id="postRelatedProductsTitle" defaultMessage="We talk about it in this article" /></span>
                {relatedProductsFound.map((product, i) => <ProductListItem key={product._id} product={product} viewType="list" />)}

                <ProductRelatedPosts products={relatedProductsFound} page={page} />
            </Container>
        );
    };

    const renderContent = (content, key, isFullColumn = false) => {
        const text = getContentBlockMixedItem(content, 'text') && <HtmlComponent text={getContentBlockMixedItem(content, 'text').text} />;
        const image = getContentBlockMixedItem(content, 'image') && getContentBlockMixedItem(content, 'image').path && <CmsBlockItem item={getContentBlockMixedItem(content, 'image')} type="image" className="mt-3 mt-md-0 w-100 rounded post-default-image" />;
        const cta = getContentBlockMixedItem(content, 'call-to-action') && getContentBlockMixedItem(content, 'call-to-action').text && <div className="text-center"><Button variant={getContentBlockMixedItem(content, 'call-to-action').color || 'warning'} size="lg" as={PredictiveLink} to={getContentBlockMixedItem(content, 'call-to-action').path} className="mt-3">{getContentBlockMixedItem(content, 'call-to-action').text} <Icon icon="chevron-right" /></Button></div>;
        const position = getContentBlockMixedItem(content, 'position') && getContentBlockMixedItem(content, 'position').text;
        const promotion = getContentBlockMixedItem(content, 'promotion') && (
            <div className="mb-3 my-2 px-3 py-4 bg-warning rounded" style={{ minHeight: '400px' }}>
                <CmsPanel slug={getContentBlockMixedItem(content, 'promotion').text} />
            </div>
        );
        return (
            <div key={key} className={classnames('mb-5', !isFullColumn && 'critical-css-limiter')}>
                {
                    isFullColumn
                    ? (
                        <Fragment>
                            {text}
                            {image}
                            {cta}
                        </Fragment>
                    ) : (
                        <Row>
                            {
                                (!position || position === 'right')
                                ? (
                                    <Fragment>
                                        <Col xs="12" md={(image || promotion) ? 8 : 12}>
                                            <div className="d-block d-md-none">
                                                {renderRelatedProduct(key)}
                                            </div>
                                            {text}
                                            {cta}
                                        </Col>
                                        {(image || promotion) && (
                                            <Col md={(image || promotion) ? 4 : 12}>
                                                {image}
                                                {promotion}
                                                <div className="d-none d-md-block">
                                                    {renderRelatedProduct(key)}
                                                </div>
                                            </Col>
                                        )}
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        {(image || promotion) && (
                                            <Col md={(image || promotion) ? 4 : 12}>
                                                {image}
                                                {promotion}
                                                <div className="d-none d-md-block">
                                                    {renderRelatedProduct(key)}
                                                </div>
                                            </Col>
                                        )}
                                        <Col xs="12" md={(image || promotion) ? 8 : 12}>
                                            {text}
                                            {cta}
                                        </Col>
                                    </Fragment>
                                )
                            }
                        </Row>
                    )
                }
            </div>
        );
    };

    const defaultImage = cover || (getContentBlockMixedItem((contents.find(content => (getContentBlockMixedItem(content, 'image') || {}).path)), 'image') || {}).path;

    return (
        <Fragment>
            <Helmet
                meta={[
                    {
                        name: 'robots',
                        content: 'index, follow, max-image-preview:large',
                    },
                    {
                        name: 'og:image',
                        content: defaultImage && getFile(defaultImage),
                    },
                ]}
            />

			<PageCover
				page={page}
				coverKey="image"
				defaultCover={defaultImage}
				beforeTitle={(
					<Container>
						<Breadcrumbs crumbs={[...(breadcrumbs || []), !breadcrumbs.length > 0 && { path: blogPage ? blogPage.slug : '/blog', text: 'Blog' }]} />
					</Container>
				)}
				afterTitle={(
					<span>
						{tags.map(tag => <span key={tag.text}><Badge bg="danger" pill className="bg-opacity-75">{tag.text}</Badge>{' '}</span>)}
					</span>

				)}
			/>

            <Container fluid="xl" className={classnames('mt-lg-n3')}>
                <Card body className={classnames('pt-lg-5')}>
                    {contents && contents.length > 0 && contents.map((content, index) => renderContent(content, index))}
                    <SocialShare title={title} image={defaultImage && getFile(defaultImage)} text={contents.slice(0, 1).reduce((text, content) => `${text} ${(getContentBlockMixedItem(content, 'text') || {}).text || ''}`, '')} />
                </Card>
                {false && <Button size="lg" block as={Link} to={blogPage ? blogPage.slug : '/blog'}><FormattedMessage id="cmsBlogView" defaultMessage="Check the blog" /> <Icon icon="chevron-right" /></Button>}
            </Container>

            {renderRelatedProducts()}

            <PreFooter />

            <JSONLD dangerouslyExposeHtml>
                <Generic
                    type="article"
                    jsonldtype="Article"
                    schema={{
                        name: title,
                        url: `${config.url}${intl.locale}/${page.slug}`,
                        articleBody: contents.reduce((text, content) => `${text} ${(getContentBlockMixedItem(content, 'text') || {}).text || ''}`, ''),
                        image: defaultImage && getFile(defaultImage),
                        author: config.url,
                    }}
                />
            </JSONLD>
        </Fragment>
    );
}

function mapStateToProps(store, props) {
    return {
        blogPage: memoizedGetPages(store, (props.intl || {}).locale, 'blog')[0],
        products: memoizedGetProducts(store, {}), // getProductsFromStore(store),
    };
}

PostDefault.propTypes = {
    intl: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
    blogPage: PropTypes.object,
    products: PropTypes.arrayOf(PropTypes.object),
};

export default connect(mapStateToProps)(injectIntl(PostDefault));
