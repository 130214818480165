import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Card } from 'react-bootstrap';

import { dateFormat } from '../../../../util/date.js';
import { truncate, nl2br } from '../../../../util/text.js';

import { REVIEW_SCORE_MAX } from '../../ReviewActions.js';

import Icon from '../../../../components/Content/Icon.js';
import HtmlComponent from '../../../../components/Text/HtmlComponent.js';

const REVIEW_IMAGES_COUNT = 10;

function ReviewListItem({ review, isSummary = false, index = null }) {
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		setMounted(true);
	}, []);

    // const wrapper = forwardRef(null);
    const text = isSummary ? `${truncate(review.text)}...` : review.text;
    const isLongReview = text.length > 80;

    const renderText = () => {
        return (
            <div className={classnames('px-4', !isLongReview && 'text-lg')}>
                <HtmlComponent text={nl2br(text)} />
            </div>
        );
    };

	const reviewIndex = index !== null ? (index > REVIEW_IMAGES_COUNT ? (index % REVIEW_IMAGES_COUNT || REVIEW_IMAGES_COUNT) : index) : Math.floor(Math.random() * REVIEW_IMAGES_COUNT) + 1;

    return (
        <Card className="mb-2" style={{ fontSize: '0.8rem' }}>
            <Card.Img src={`/assets/images/reviews/review-${reviewIndex}.jpg`} alt={`Review n°${reviewIndex}`} rel="presentation" loading="lazy" />
            <Card.ImgOverlay>
                <div className={classnames('w-100', 'mt-4', 'mt-md-5', 'pt-3', 'm-auto', 'bg-white', 'rounded', 'text-center', isLongReview ? 'w-md-75' : 'w-md-50')} style={{ overflow: 'auto', maxHeight: '400px', opacity: '0.85' }}>
					{mounted && (
						<div className="mx-auto mb-3 mb-md-4 starRating starRatingSmall">
							<div style={{ width: `${(review.score * 100) / REVIEW_SCORE_MAX}%` }}>
								{' '}
							</div>
						</div>
					)}
                    {review.title && <strong className="d-block text-danger h5">{review.title}</strong>}
                    {renderText()}
                    <em className="d-block my-3">
                        <strong>{review.name}</strong>, <FormattedMessage id="dateThe" defaultMessage="the {date}" values={{ date: dateFormat(review.dates.creation, 'DD/MM/YYYY') }} />
                    </em>
                </div>
            </Card.ImgOverlay>
            {!isSummary && review.answer && (
                <Card.Footer>
                    <span className="d-block text-start font-weight-bold"><FormattedMessage id="reviewAnswer" defaultMessage="Answer" /></span>
                    <div style={{ overflow: 'auto', maxHeight: '200px' }}>
                        {review.answer}
                    </div>
                </Card.Footer>
            )}
        </Card>
    );
}

ReviewListItem.propTypes = {
    review: PropTypes.object.isRequired,
    isSummary: PropTypes.bool,
    index: PropTypes.number,
};

export default ReviewListItem;
