import React, { Fragment, PureComponent, Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import { Button, ButtonGroup } from 'react-bootstrap';

import config from '../../../../config.js';

import { getFile } from '../../../../util/file.js';

import { getCmsPageRequest, getPageBySlugAndLanguage, getPanelBySlugAndLanguage, getContentBlockItemValue, getCmsPageSeo, getCmsPageSeoTitle, getCmsPageSeoDescription, memoizedGetPanelBySlugAndLanguage } from '../../CmsActions.js';
import { getCmsViewer, getModel } from '../../CmsModels.js';
import { getProductBySlug } from '../../../Product/ProductActions.js';
import { getLoggedUser } from '../../../User/UserActions.js';
import { getLanguagePath, getEnabledLanguages } from '../../../Intl/IntlHelper.js';
import { isCanonicalForced } from '../../../App/AppActions.js';
import { defaultLanguage } from '../../../../../Intl/setup.js';

import withRouter from '../../../../components/Router/WithRouter.js';
import Loader from '../../../../components/Loader/Loader.js';
import ErrorPage from '../../../Error/pages/ErrorPage.js';
import CmsEditLinks from '../../components/form/CmsEditLinks.js';
import ProductViewPage from '../../../Product/pages/view/ProductViewPage';

class CmsPage extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
        };

		// console.log('CmsPage::: Constructor');
    }

    componentDidMount() {
		// console.log('CmsPage::: Mounted');
        if(!this.props.page || this.props.search.get('refresh')) {
			this.setContent();
        }
        if(this.props.page) {
			// this.props.dispatch(getCmsPageTranslationsRequest(this.props.page._id));
            this.handleAnchor();
        }
    }

    componentDidUpdate(prevProps, prevState) {
		// console.log('CmsPage::: Updated');
        if(
			(
				(prevProps.params.slug || prevProps.params['*']) !== (this.props.params.slug || this.props.params['*'])
				|| prevProps.intl.locale !== this.props.intl.locale
			) && !this.props.page
        ) {
            this.setContent();
        }

        if((prevState.isLoading && !this.state.isLoading) || this.props.location?.hash) {
            // this.props.dispatch(getCmsPageTranslationsRequest(this.props.page._id));
            this.handleAnchor();
        }
    }

    setContent = () => {
		// console.log('CmsPage::: setContent', this.props.params);
        this.setState({
            isLoading: true,
        });
        return this.props.dispatch(getCmsPageRequest(this.props.params.slug || this.props.params['*'], this.props.intl.locale, !!this.props.search.get('refresh'), (this.props.location || {}).search)).then(content => {
            this.setState({
                isLoading: false,
            });
        }).catch(err => {
            this.setState({
                isLoading: false,
            });
        });
    };

    getSeo() {
        const { page, forceCanonical, intl } = this.props;
        let title = getCmsPageSeoTitle(page, this.props.siteTitlePanel && getContentBlockItemValue(this.props.siteTitlePanel.blocks, 'text'));
		let description = getCmsPageSeoDescription(page);

		if(parseInt(this.props.search?.get('page'), 10) > 1) {
			title = `${title} - ${parseInt(this.props.search?.get('page'), 10)}`;
			description = '';
		}
        let seoData = {
            title,
            meta: [
                {
                    name: 'description',
                    content: description,
                },
				{
					name: 'og:type',
					content: 'website',
				},
                {
                    name: 'og:title',
                    content: title,
                },
                {
                    name: 'og:description',
                    content: description,
                },
                {
                    name: 'robots',
                    content: getCmsPageSeo(page, 'hideOnSitemap') ? 'noindex, nofollow' : 'index, follow',
                },
            ],
            link: [],
        };

        if(page) {
            const model = getModel(page.type, page.model) || {};

            if(page.seo) {
                const { seo } = this.props.page;
                seoData = {
                    ...seoData,
                    meta: [
                        ...(seoData.meta || []),
                        {
                            name: 'keywords',
                            content: seo.keywords || '',
                        },
                        {
                            name: 'og:url',
                            content: `${config.url}${getLanguagePath(`/${page.slug.replace('*', '')}`, intl.locale).substring(1)}`,
                        },
                    ],
                    link: [],
                };
            }

            const allPageTranslations = (page.translations || []).concat([page]);
            const defaultTranslation = allPageTranslations.find(translation => translation.language === defaultLanguage);
            seoData.link = (seoData.link || [])
            .concat(getEnabledLanguages().map(language => allPageTranslations.find(translation => translation.language === language)).filter(translation => translation).map(existingTranslation => ({
                href: `${config.url}${getLanguagePath(`/${(existingTranslation.slug || '').replace('*', '')}`, existingTranslation.language).substring(1)}${this.props.location?.search || ''}`,
                rel: 'alternate',
                hreflang: existingTranslation.language,
            })))
            .concat(
                forceCanonical || (!(model.options || {}).disableCanonical && ((page.slug.replace('*', '') !== (this.props.params.slug || '')) || Object.fromEntries(this.props.search || {}).length))
                ? [{
                    href: `${config.url}${getLanguagePath(`/${page.slug.replace('*', '')}`, intl.locale).substring(1)}${parseInt(this.props.search?.get('page'), 10) > 1 ? `?page=${this.props.search?.get('page')}` : ''}`,
                    rel: 'canonical',
                }]
                : [{
                    href: '',
                    rel: 'canonical',
                }],
            );

            const coverDesktop = getContentBlockItemValue(this.props.page.blocks, 'cover', 'path');
            const cover = getContentBlockItemValue(this.props.page.blocks, 'cover', 'path', { useForMobile: this.props.appIsMobile }) || coverDesktop;

            if(cover) {
                seoData.meta.push({
                    name: 'og:image',
                    content: getFile(coverDesktop || cover),
                });
                seoData.link.push({
                    href: getFile(cover),
                    rel: 'preload',
                    type: `image/${cover.split('.').pop()}`,
                    as: 'image',
					fetchpriority: 'high',
                });
            }
        }

        // If no translation exists for current locale, indicates canonical to prevent duplicate content on non default locale pages
        if(!this.props.page && this.props.intl.locale !== defaultLanguage) {
            // console.log('Missing page & not default locale => canonical');
            seoData.link = seoData.link.concat(
                [{
                    href: `${config.url}${getLanguagePath(`/${this.props.params.slug || this.props.params['*'] || ''}`, defaultLanguage).substring(1)}${parseInt(this.props.search?.get('page'), 10) > 1 ? `?page=${this.props.search?.get('page')}` : ''}`,
                    rel: 'canonical',
                }],
            );
        }

        page && (seoData.meta || []).push({
            name: 'app:cms',
            content: `${page.type}/${page.model}`,
        });
        // console.log(seoData);
        return seoData;
    }

    handleAnchor = () => {
        if(typeof window !== 'undefined' && this.props.location?.hash) {
            setTimeout(() => {
                const elt = window.document.getElementById(this.props.location?.hash.replace('#', ''));
                elt && elt.scrollIntoView({ behavior: 'smooth' });
            }, 500);
        }
    };

    renderPageModel() {
        if(this.props.page && !this.state.isLoading) {
            const ModelComponent = getCmsViewer(this.props.page);
            return ModelComponent && (
				<ModelComponent page={this.props.page} />
			);
        }
        return null;
    }

    render() {
		// console.log('CmsPage::: render');
        const { location, page, product } = this.props;

        if(!page) {
            if(product) {
                return (
					<ProductViewPage page={{}} product={product} />
				);
            }
            return !this.state.isLoading && (this.props.isMounted) && <ErrorPage />;
        }

        // console.log('CmsPage::: SEO', this.getSeo());

        return (
            <Fragment key={location?.pathname}>
                <Helmet key={location?.pathname} {...this.getSeo()} />
                {this.renderPageModel()}
				<CmsEditLinks page={page} product={product} />
            </Fragment>
        );
    }
}

function mapStateToProps(store, props) {
    return {
        user: getLoggedUser(store),
        page: getPageBySlugAndLanguage(store, props.params?.slug || (props.params || {})['*'], store.intl.locale),
        product: getProductBySlug(store, props.params?.slug || (props.params || {})['*'], store.intl.locale),
        siteTitlePanel: memoizedGetPanelBySlugAndLanguage(store, 'site-title', (store.intl || {}).locale),
        isMounted: store.app.isMounted,
		appIsMobile: store.app.isMobile,
        forceCanonical: isCanonicalForced(store),
    };
}

CmsPage.defaultProps = {
    params: {},
    user: null,
    page: null,
    product: null,
    siteTitlePanel: null,
    isMounted: false,
    appIsMobile: false,
    forceCanonical: false,
};

CmsPage.propTypes = {
    params: PropTypes.object,
    location: PropTypes.object.isRequired,
    search: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    user: PropTypes.object,
    page: PropTypes.object,
    product: PropTypes.object,
    siteTitlePanel: PropTypes.object,
    isMounted: PropTypes.bool,
    appIsMobile: PropTypes.bool,
    forceCanonical: PropTypes.bool,
};

// CmsPage.prefetch = 'Youhouhouhouh';

export default withRouter(connect(mapStateToProps)(injectIntl(CmsPage)));
