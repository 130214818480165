import { localizationData, defaultLanguage, enabledLanguages } from '../../../Intl/setup.js';
import { getUserPreference, setUserPreferenceRequest } from '../User/UserActions.js';

import callApi from '../../util/apiCaller.js';
import { setDateLanguage } from '../../util/date.js';
import { displayErrors } from '../Error/ErrorActions.js';

// Export Constants
export const SWITCH_LANGUAGE = 'SWITCH_LANGUAGE';

export function initLanguage(lang = null, user = null, store = null) {
    // console.log('initLanguage', lang);
    return dispatch => {
        if(!lang) {
            if(store && store.intl && store.intl.isInit) {
                lang = store.intl.locale;
            } else if(global.navigator && global.navigator.language) {
                const navigatorLanguage = global.navigator.language.split('-')[0];
                lang = navigatorLanguage;
            }
            // const initLocale = global.navigator && global.navigator.language || 'en';
        }
        if(enabledLanguages.indexOf(lang) === -1) {
            // console.log('lang not enabled!', lang);
            lang = defaultLanguage;
        }
        return callApi(`intl/language/${lang}`).then(res => {
            if(res) {
                dispatch(switchLanguage(lang, res));
                if(user && lang && lang !== getUserPreference(user, 'language')) {
                    dispatch(setUserPreferenceRequest('language', lang));
                }
                // console.log('Lang set', lang);
                return lang;
            }
            return null;
        }).catch(err => {
            return null;
        });
    };
}

export function switchLanguage(newLang, data = null) {
    if(newLang && (data || localizationData[newLang])) {
        setDateLanguage(newLang);
        const messages = (data || localizationData[newLang] || {}).messages || {};

        // console.log('Messages from switchLanguage', messages);
        return {
            type: SWITCH_LANGUAGE,
            locale: newLang,
            messages,
            isInit: true,
        };
    }
    return {
        type: SWITCH_LANGUAGE,
    };
}

export function translateContentRequest(text, from, to) {
    return callApi('intl/translate', 'post', { text, from, to }).then(result => {
        displayErrors(result.isOk ? (result.confidencePercent > 90 ? 'success' : 'warning') : 'error', `Fiabilité de traduction: ${Math.round(result.confidencePercent)}%`);
        return result;
    }).catch(err => {
        displayErrors('error', err.message || err);
    });
}
