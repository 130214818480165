import fetch from 'isomorphic-fetch';

import config from '../config.js';
import storage, { storagePersistent } from './storage.js';

import { notifyError } from '../components/Error/ErrorBoundary.js';

import { LOGIN_TOKEN_NAME } from '../modules/User/UserActions.js';
import { getHashKeys } from './security.js';
import { dateFormat, dateUtc } from './date.js';


// export const API_URL = (typeof window === 'undefined' || process.env.NODE_ENV === 'development')
//     ? process.env.BASE_URL || (`http://localhost:${config.port}/api`)
//     : '/api';

// export const API_URL = `${config.api}/api`;
export const API_URL = `${config.api}`;

// console.log(config, process.env, API_URL);

const MAX_ATTEMPTS = 3;

export default function callApi(endpoint, method = 'get', body = null, headersContent = {}, isExternal = false, attempts = 0) {
    const headers = new Headers({
        'Content-Type': 'application/json',
        ...headersContent,
    });
    const apiUrl = isExternal ? endpoint : `${API_URL}/${endpoint}`;

    if(!isExternal) {
        if(storagePersistent && storagePersistent.getItem(LOGIN_TOKEN_NAME)) {
            headers.append('Authorization', `Bearer ${storagePersistent.getItem(LOGIN_TOKEN_NAME)}`);
        }
        if(config.application.security.secureApiCall) {
            const code = `${method.toUpperCase()}.${apiUrl.toLowerCase()}.${dateFormat(dateUtc(), 'YYYY/MM-DD')}`;
            const { key, salt } = getHashKeys(code);
            headers.append(config.application.security.secureApiCallHeader, `${key}.${salt}`);
        }
    }

    return new Promise((resolve, reject) => {
        setTimeout(() => {
            try {
                // Try to replace fetch by request
                const fetchOptions = {
                    headers,
                    method,
                };
                if(!['GET', 'HEAD'].includes(method.toUpperCase()) && body) {
                    fetchOptions.body = isExternal ? body : JSON.stringify(body);
                }
                fetch(apiUrl, fetchOptions)
                .then(response => (
                    isExternal
                    ? { response }

                    : response.text()
                        .then(text => ({ json: JSON.parse(text), response }))
                    ))

                    // : response.json()
                    //     .then(json => ({ json, response })
                    //     .catch(err => {
                    //         notifyCallApiError(err, apiUrl, method, body, headersContent, isExternal, attempts);
                    //         reject(response.statusText || 'JsonParseError');
                    // }))))
                .then(({ json, response }) => {
                    // console.log('API Json Response', json, response);
                    if(!response.ok) {
                        // console.error('API Json Response Error', json, response);
                        // return reject(json.message);
                        if(!isExternal && attempts < MAX_ATTEMPTS && isAuthFail(json)) {
                            // console.log('Recall API', attempts);
                            // return refreshToken(true).then(() => {
                            //     return callApi(endpoint, method, body, headersContent, isExternal, attempts + 1);
                            // }).catch(err => {
                            //     notifyCallApiError(err, apiUrl, method, body, headersContent, isExternal, attempts);
                            //     return reject(json);
                            // });
                        }
                        return reject(json);
                    }
                    // console.log(response, json);
                    return json || response;
                })
                .then(
                    response => resolve(response),
                    err => reject(err),
                ).catch(err => {
                    console.error(err);
                    reject(new Error('FetchApiError'));
                    notifyCallApiError(err, apiUrl, method, body, headersContent, isExternal, attempts);
                    return err;
                });
            } catch (err) {
                console.error('FetchApiError', err);
                notifyCallApiError(err, apiUrl, method, body, headersContent, isExternal, attempts);
                reject(new Error('FetchApiError'));
            }
        }, 1000 * attempts);
    });
}

export function callExternalApi(endpoint, method = 'get', body = null, headersContent = {}) {
    return callApi(endpoint, method, body, headersContent, true);
}

export function buildQuery(obj, numPrefix, tempKey) {
    const outputString = [];

    Object.keys(obj).forEach(val => {
        let key = val;

        if(numPrefix && !Number.isNaN(key)) {
            key = numPrefix + key;
        }

        key = encodeURIComponent(key.replace(/[!'()*]/g, escape));
        if(tempKey) {
            key = `${tempKey}[${key}]`;
        }

        if(typeof obj[val] === 'object') {
            const query = buildQuery(obj[val], null, key);
            outputString.push(query);
        } else {
            const value = typeof obj[val] === 'string' ? encodeURIComponent(obj[val].replace(/[!'()*]/g, escape)) : obj[val];
            outputString.push(`${key}=${value}`);
        }
    });

    return outputString.join('&');
}

function isAuthFail(json) {
    const authFailRegex = /(TokenExpired|InvalidTokenUse|TokenNotFound|NoAccessToken|Unauthorized)/gi;
    return authFailRegex.test(json.name) || authFailRegex.test(json.message);
}

function notifyCallApiError(err, endpoint, method, body, headersContent, isExternal, attempts) {
    console.log('Notify Error', err, endpoint);
    notifyError(err, { severity: 'error', request: { endpoint, method, body, headersContent, isExternal, attempts } });
}
