import React, { Component, Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';

import config from '../../../../config.js';
import storage from '../../../../util/storage.js';

import { subscribeNewsletterRequest, getLoggedUser, checkPassword, isUserSubscribedToNewsletter, getCurrentUserData } from '../../UserActions.js';
import { getCurrentCurrency } from '../../../Currency/CurrencyActions.js';

import LazyLoader from '../../../../components/Content/LazyLoader.js';
import Icon from '../../../../components/Content/Icon.js';

function UserNewsletterSubscribe({ dispatch, intl, user = null, userData = {}, currency = '' }) {
	const [isMounted, setIsMounted] = useState(false);
	const [email, setEmail] = useState((storage && storage.getItem('userEmail')) || '');
	const [result, setResult] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [errorFields, setErrorFields] = useState([]);

	useEffect(() => {
		setIsMounted(true);
	}, []);

    const handleReset = () => setResult(null);

    const handleSubmit = event => {
        if(user || (email && checkPassword(email))) {
			setIsLoading(true);
			setErrorFields([]);
            dispatch(subscribeNewsletterRequest('general', true, { email, preferences: { language: intl.locale, currency }, ...(userData || {}) })).then(isOk => {
				setIsLoading(false);
				setResult(isOk);
            });
        } else {
			setErrorFields(['email']);
        }
    };

	const isSubscribed = isUserSubscribedToNewsletter(user, 'general');

	let content = '';
	if(result !== null) {
		content = (
			<strong className={classnames('mb-2', 'text-danger')}>
				{
					result
					? (
						<p className="text-danger mb-3">
							<FormattedMessage id="userNewsletterSubscribeSuccess" defaultMessage="Well done ! You have successfully subscribed to our newsletter!" />
						</p>
					) : (
						<Fragment>
							<FormattedMessage id="userNewsletterSubscribeError" defaultMessage="We were unable to register you as a subscriber, please try again." />
							<Button variant="link" size="sm" onClick={handleReset}><Icon icon="sync" /></Button>
						</Fragment>
					)
				}
			</strong>
		);
	} else if(isMounted && user) {
		content = isSubscribed ? (
				<p className="text-danger mb-3">
					<FormattedMessage id="userSubscribeNewsletterConfirmation" defaultMessage="You already subscribed to our newsletter! Thank you!" />
				</p>
			) : <Button variant="danger" size="lg" onClick={handleSubmit} className="d-inline-block rounded-sm"><FormattedMessage id="userNewsletterSubscribeButton" defaultMessage="Subscribe to newsletter" /></Button>;
	} else {
		content = (
			<Form.Group>
				<Row className="g-0">
					<Col xs="12" sm="6">
						<div className="position-relative pe-0 pe-sm-2">
							<Form.Label htmlFor="newsletterEmailField"><FormattedMessage id="userEmail" defaultMessage="Email" /></Form.Label>
							<Form.Control id="newsletterEmailField" type="email" name="email" value={email} disabled={isLoading} onChange={event => setEmail(event.target.value)} placeholder={intl.formatMessage({ id: 'userEmailPlaceholder', defaultMessage: 'Please fill your email' })} isInvalid={errorFields.includes('email')} className={classnames(errorFields.includes('email') && 'has-danger')} />
							{errorFields.includes('email') && <Form.Control.Feedback tooltip type="invalid"><FormattedMessage id="userEmailError" defaultMessage="Your email address is incorrect!" /></Form.Control.Feedback>}
						</div>
					</Col>
					<Col className="mt-2 mt-sm-0">
						<Form.Label className="d-none d-sm-block">&nbsp;</Form.Label>
						<Button variant="danger" onClick={handleSubmit} disabled={!email || isLoading} className="d-inline-block rounded-sm"><FormattedMessage id="userNewsletterSubscribeButton" defaultMessage="Subscribe to newsletter" /> {isLoading && <Icon icon="spinner" spin />}</Button>
					</Col>
				</Row>
			</Form.Group>
		);
	}
	return (
		<Card body className="position-relative mt-3 mt-md-0 p-2 p-md-5 bg-transparent text-dark">
			<span className="d-block h2 w-100 w-md-75"><FormattedMessage id="userNewsletterTitle" defaultMessage="Newsletter" /></span>
			{!isSubscribed && (
				<p>
					<FormattedMessage id="userNewsletterText1" defaultMessage="Receive our new products, our tips and our promotions directly in your email box" /><br />
					<strong className="text-danger"><FormattedMessage id="userNewsletterText2" defaultMessage="You may well receive a surprise when you subscribe..." /> 🤞</strong>
				</p>
			)}
			{content}
			<p>
				<small><FormattedMessage id="userNewsletterText3" defaultMessage="We will only send you information related to our site, only twice a month, and you can unsubscribe at any time!" /></small>
			</p>
			<LazyLoader>
				<picture>
					<source srcSet={`${config.url}assets/images/newsltter-icon.webp`} />
					<img src={`${config.url}assets/images/newsltter-icon.png`} width="210" height="210" className="position-absolute d-none d-md-inline" style={{ top: '-4rem', right: '0' }} alt="Newsletter" loading="lazy" />
				</picture>
			</LazyLoader>
		</Card>
	);
}

function mapStateToProps(store, props) {
    return {
        user: getLoggedUser(store),
        userData: getCurrentUserData(store),
        currency: getCurrentCurrency(store),
    };
}

UserNewsletterSubscribe.propTypes = {
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    user: PropTypes.object,
    userData: PropTypes.object,
    currency: PropTypes.string,
};

export default connect(mapStateToProps)(injectIntl(UserNewsletterSubscribe));
