import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Container, Card } from 'react-bootstrap';

import { connect } from 'react-redux';
import { getFile } from '../../../../../util/file.js';

import { getContentBlockItemsByName, getContentBlockItemValue } from '../../../CmsActions.js';

import HtmlComponent from '../../../../../components/Text/HtmlComponent.js';
import ProductRelatedPosts from '../../../../Product/components/view/ProductRelatedPosts.js';
import { getFilteredProducts, getProductAttributeList, memoizedGetProducts } from '../../../../Product/ProductActions.js';
import { getDefaultLanguage } from '../../../../Intl/IntlHelper.js';

function PanelRecommandation({ intl, panel, products = [] }) {
    const cover = getContentBlockItemValue(panel.blocks, 'cover', 'path');
    const title = getContentBlockItemValue(panel.blocks, 'title');
    const content = getContentBlockItemValue(panel.blocks, 'content');
    const categories = getContentBlockItemsByName(panel.blocks, 'category').map(cat => cat.text).filter(cat => cat);
    return (
        <div>
            {cover && (
                <div className="py-4 bg-white header-cover" style={{ backgroundImage: cover ? `url(${getFile(cover)})` : '', height: cover ? '20rem' : 'auto' }}>
                    {' '}
                </div>
            )}
            <Container fluid className={classnames(cover && 'mt-n10', !cover && ['py-4', 'border-top'])}>
                <Card className="mb-3">
                    <Card.Body>
                        <div className="mb-3 text-center">
                            <h2 className="d-inline-block border-half-warning text-primary text-center">
                                {title || <FormattedMessage id="productRecommandationTitle" defaultMessage="How to taste it" />}
                            </h2>
                        </div>
                        <div className="px-2 px-md-5">
                            {content && <HtmlComponent text={content} />}
							{categories?.length > 0 && (
								<ProductRelatedPosts products={products.filter(product => [...getProductAttributeList(product, 'categories', intl.locale), ...getProductAttributeList(product, 'categories', getDefaultLanguage())].some(productCategory => categories.map(cat => `${cat}`.toLowerCase()).includes(productCategory.toLowerCase())))} hideTitle />
							)}
                        </div>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
}

function mapStateToProps(store, props) {
	return {
		products: memoizedGetProducts(store, { isActive: true, isPack: false, isVariation: false }),
	};
}

PanelRecommandation.propTypes = {
	intl: PropTypes.object.isRequired,
    panel: PropTypes.object.isRequired,
	products: PropTypes.object
};

export default connect(mapStateToProps)(injectIntl(PanelRecommandation));
