import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Card, Button, Badge } from 'react-bootstrap';

import { truncateToChar } from '../../../../util/text.js';

import { getTotalQuantity } from '../../../Cart/CartActions.js';
import { getProductPrice, getProductSlug, getPackProducts } from '../../ProductActions.js';
import { getUserGroup, getUserGroupOption } from '../../../User/UserGroupActions.js';
import { getTranslation } from '../../../Intl/IntlHelper.js';

import withRouter from '../../../../components/Router/WithRouter.js';
import Icon from '../../../../components/Content/Icon.js';
import FileLoader from '../../../../components/Content/FileLoader.js';
import HtmlComponent from '../../../../components/Text/HtmlComponent.js';
import Price from '../../../Currency/components/view/Price.js';
import ProductWeight from '../view/ProductWeight.js';
import ProductAddToCart from '../form/ProductAddToCart.js';
import { getReferral } from '../../../App/AppActions.js';

function PackListItem({ navigate, intl, pack, products, userGroup = null, isLarge = false, referral = null }) {
    // const [isLoading, setLoading] = useState(false);

    // if(!(pack.items || []).length) {
    //     return null;
    // }

    const displayPriceHT = getUserGroupOption(userGroup, 'displayPriceHT');

    const productPrice = getProductPrice(pack, 1, userGroup, !displayPriceHT, referral);

	const description = getTranslation(pack, 'description', intl.locale);

    const getProductUrl = () => `/${intl.locale}/${getProductSlug(pack, intl.locale)}`;

    const viewProduct = () => {
        navigate(getProductUrl());
    };

    const getDiscount = () => {
        return 100 - Math.floor((productPrice * 100) / pack[displayPriceHT ? 'priceHT' : 'priceTTC']);
    };

    // const handleAddToCart = () => {
    //     setLoading(true);
    //     dispatch(setCartItem(getCartItemFromProduct(pack, 1, intl.locale, userGroup)));
    //     setLoading(false);
    //     navigate(`/${intl.locale}/cart`);
    // };

    return (
        <Card className="overflow-hidden mb-4 product-list-item shadow">
            <div className="product-image-wrapper position-relative" style={{ height: '220px', overflow: 'hidden' }}>
                {pack.pictures && pack.pictures.length > 0 && (
                    <FileLoader filename={pack.pictures[0]} useAppUrl alt={getTranslation(pack, 'name', intl.locale)} className="card-img mt-md-n5" style={{ maxWidth: '100%' }} loading="lazy" />
                )}
                {pack.items.length > 0 && <span className="h5 position-absolute mb-0" style={{ bottom: '20px', right: '1rem' }}><Badge bg="superlight" pill className="text-dark"><FormattedMessage id="productCount" defaultMessage="{count} product(s)" values={{ count: getTotalQuantity(pack.items, true) }} /></Badge></span>}
                {productPrice > 0 && productPrice < pack[displayPriceHT ? 'priceHT' : 'priceTTC'] && (
                    <Badge bg="danger" pill style={{ position: 'absolute', top: '10px', right: '10px' }}><span className="display-5">-{getDiscount()}%</span></Badge>
                )}
            </div>
            <Card.Body className="mb-0">
                <div className="d-flex justify-content-between">
                    <div className="pe-2">
                        <h4 className={classnames('d-block', 'mb-0', isLarge ? 'h5' : 'h6')}><a href={getProductUrl()}>{getTranslation(pack, 'name', intl.locale)}</a></h4>
                        <strong className="h6 text-black-75"><ProductWeight product={pack} hideLabel /></strong>
                    </div>
                    <div className="text-end">
                        <div className="d-flex justify-content-between">
                            {productPrice > 0 && productPrice < pack[displayPriceHT ? 'priceHT' : 'priceTTC'] && <small className="d-block text-lg mb-0 me-1 text-black-75 text-crossed"><Price amount={pack[displayPriceHT ? 'priceHT' : 'priceTTC']} formatPrice /></small>}
                            <strong className="h3 text-danger"><Price amount={productPrice} /></strong><br />
                            {displayPriceHT && <small className="text-black-75"><em><FormattedMessage id="productPriceHT" defaultMessage="Excl. tax" /></em></small>}
                        </div>
                    </div>
                </div>
                {description && (
					<div style={{ height: '3em' }}>
						<HtmlComponent text={truncateToChar(description)} />
					</div>
				)}
            </Card.Body>
            <Card.Footer className="mt-0 bg-white d-flex justify-content-between border-0">
                <Button variant="link" className="text-dark" as="a" href={getProductUrl()}><FormattedMessage id="productDetailViewPackButton" defaultMessage="See pack" /> <Icon icon="chevron-right" /></Button>
                {
                    pack.isAvailable
                    // ? <Button variant="secondary" onClick={handleAddToCart}>{isLoading ? <Icon icon="spinner" spin /> : <Icon icon="shopping-cart" />} <FormattedMessage id="cartAddTo" defaultMessage="Add to cart" /></Button>
                    ? <ProductAddToCart product={pack} variant="secondary" />
                    : <Button variant="link" disabled className="m-0 p-0 mt-n2 border-0 text-danger"><FormattedMessage id="productNotAvailable" defaultMessage="Not available" /></Button>
                }
            </Card.Footer>
        </Card>
    );
}

// tag={Link} to={`/${intl.locale}/${company.slug}`}

function mapStateToProps(store, props) {
    return {
        userGroup: getUserGroup(store),
        products: props.pack ? getPackProducts(store, props.pack) : [],
        referral: getReferral(store),
    };
}

PackListItem.propTypes = {
    navigate: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    pack: PropTypes.object.isRequired,
    userGroup: PropTypes.object,
    products: PropTypes.arrayOf(PropTypes.object).isRequired,
    isLarge: PropTypes.bool,
    referral: PropTypes.string,
};

export default connect(mapStateToProps)(withRouter(injectIntl(PackListItem)));
