import React, { PureComponent, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { getDefaultLanguage, getEnabledLanguages, getLanguagePath, getPreferedLanguage } from './IntlHelper.js';
import withRouter from '../../components/Router/WithRouter.js';

function IntlWrapper({ /* navigate, location, */ locale = getDefaultLanguage(), messages = [], children }) {
    // useEffect(() => {
    //     if(!intl.locale || !getEnabledLanguages().includes(intl.locale)) {
    //         console.log('Intl::: Unknow locale', intl.locale, ' -> Redirect to', getLanguagePath(location.path, getPreferedLanguage()));
    //         navigate(getLanguagePath(location.path, getPreferedLanguage()));
    //     }
    // }, [intl.locale]);

	// console.log('IntlWrapper::: Render', locale);
    return (
        <IntlProvider key={locale} locale={locale} messages={messages}>
            {children}
        </IntlProvider>
    );
}

IntlWrapper.propTypes = {
    navigate: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    children: PropTypes.element.isRequired,
};

// Retrieve data from store as props
function mapStateToProps(store) {
	return {
		locale: store.intl.locale,
		messages: store.intl.messages,
	};
}

// export default connect(mapStateToProps)(withRouter(IntlWrapper, ['location', 'navigate']));
export default connect(mapStateToProps)(IntlWrapper);
