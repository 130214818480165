import React, { useEffect } from 'react';
import PropTypes, { func } from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Card, ButtonGroup, Button } from 'react-bootstrap';
import Icon from '../../../components/Content/Icon.js';

import { setStatusCode } from '../../App/AppActions.js';

import PageWrapper from '../../../components/Content/PageWrapper.js';
import { getLanguagePath } from '../../Intl/IntlHelper.js';

function ErrorPage({ dispatch, intl, isSSR }) {
    useEffect(() => {
        dispatch(setStatusCode(404));
    }, []);

    if(isSSR) {
        dispatch(setStatusCode(404));
    }

    return (
        <Container className="page-wrapper bg-company">
            <PageWrapper>
                <Card className="mt-5 mb-5 shadow text-center">
                    <Card.Header>
                        <Card.Title>
                            <Icon icon="danger" /> <FormattedMessage id="appBackHomeTitle" defaultMessage="Wrong Way..." />
                        </Card.Title>
                    </Card.Header>
                    <Card.Body className="px-3">
                        <FormattedMessage id="app404ErrorText" defaultMessage="We didn't find what you're looking for! Go back to homepage or your account" />
                        <ButtonGroup className="d-block mt-3    ">
                            <Button as={Link} to={getLanguagePath('/', intl.locale)} variant="outline-secondary"><Icon icon="chevron-left" /> <FormattedMessage id="appBackHomeButton" defaultMessage="Back home" /></Button>
                            <Button as={Link} to={`/${intl.locale}/user/profile`} variant="outline-primary"><Icon icon="user" /> <FormattedMessage id="userAccountMy" defaultMessage="My account" /></Button>
                        </ButtonGroup>
                    </Card.Body>
                </Card>
            </PageWrapper>
        </Container>
    );
}

function mapStateToProps(store, props) {
    return {
        isSSR: store.app.isSSR,
    };
}

ErrorPage.propTypes = {
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    isSSR: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(injectIntl(ErrorPage));
