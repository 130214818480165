import slugify from 'slugify';

import { createSelector } from 'reselect';
import { defaultLanguage } from '../../../Intl/setup.js';

import { formatPrice, getPriceCent, getPriceFromCent } from '../../util/price.js';

import { getProductPrice } from '../Product/ProductActions.js';
import { getUserOption } from '../User/UserActions.js';
import { getTranslation } from '../Intl/IntlHelper.js';
import { getUserGroupCondition } from '../User/UserGroupActions.js';

export const SET_ITEM = 'SET_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const REMOVE_ITEMS = 'REMOVE_ITEMS';
export const SET_INVOICE_ADDRESS = 'SET_INVOICE_ADDRESS';
export const SET_SHIPPING_ADDRESS = 'SET_SHIPPING_ADDRESS';
export const SET_SHIPPING_METHOD = 'SET_SHIPPING_METHOD';
export const SET_CART_OPTION = 'SET_CART_OPTION';
export const SET_CART_MULTIORDER = 'SET_CART_MULTIORDER';
export const CLEAR_CART_MULTIORDER = 'CLEAR_CART_MULTIORDER';
export const ADD_INVITATION = 'ADD_INVITATION';
export const REMOVE_INVITATION = 'REMOVE_INVITATION';
export const ADD_CART_NOTE = 'ADD_CART_NOTE';
export const SET_CART_SUBSCRIPTION = 'SET_CART_SUBSCRIPTION';
export const REMOVE_CART_SUBSCRIPTION = 'REMOVE_CART_SUBSCRIPTION';
export const CLEAR_CART = 'CLEAR_CART';

// Getters
export function getCart(store) {
    return store.cart;
}

export function getCartItems(store) {
    return store.cart.items;
}

// export function getCartItemByProduct(store, product) {
//     return store.cart.items.find(item => item.productId === product._id);
// }

export const getCartItemByProduct = createSelector(
	[
		store => store.cart.items,
		(store, product) => product,
	],
	(items, product) => items.find(item => item.productId === product._id),
);

export function getCartNote(store) {
    return store.cart.note;
}

export function getCartShipping(store) {
    return store.cart.shipping;
}

export function getCartInvoice(store) {
    return store.cart.invoice;
}

export function getCartAddress(store, type) {
    return (store.cart[type] || {}).address;
}

export function getCartVouchers(store) {
    return store.cart.vouchers;
}

export function getCartOptions(store) {
    return store.cart.options || {};
}

export function getCartMultiorder(store) {
    return store.cart.multiorder || {};
}

export function getInvitations(store, type = null) {
    return store.cart.invitations.filter(invitation => type === null || invitation.type === type);
}

export function getCartItemFromProduct(product, quantity = 1, locale = defaultLanguage, userGroup = null, options = {}) {
    if(product) {
        const { _id, __v, benefits, priceTTC, priceHT, normalPriceTTC, specialPrices, weightNet, minimumQuantity, description, composition, capacity, translations, pictures, annexes, isHighlight, isActive, ...productData } = product;
        return {
            ...productData,
            productId: product._id,
            quantity: parseInt(quantity, 10),
            priceTTC: getProductPrice(product, 1, userGroup, true, options.referral),
            priceHT: getProductPrice(product, 1, userGroup, false, options.referral),
            basePriceTTC: priceTTC,
            basePriceHT: priceHT,
            status: 'ordered',
            name: getTranslation(product, 'name', locale),
            nameAdmin: product.name,
            ...(options || {}),
        };
    }
}

export function getCartQuantityStep(product, userGroup) {
    const condition = getUserGroupCondition(userGroup, 'packFill');
    if(condition) {
        const conditionValue = getConditionValue(condition);
        if(isConditionPercentValue(condition)) {
            return Math.floor((product.packing * conditionValue) / 100);
        }
        return conditionValue;
    }
    return Math.max(product.stepQuantity || 1, 1);
}

export function getItemPackFillPercentage(item) {
    return item.quantity % item.packing
        ? Math.round(((item.quantity % item.packing) * 100) / item.packing)
        : 100;
}

export function getItemsPackFill(items, isPercent = false) {
    return items && items.length ? items.reduce((total, item) => total + (isPercent ? getItemPackFillPercentage(item) : item.quantity), 0) / items.length : 0;
}

export function getPackages(items) {
    const packages = [];
    (items || []).forEach(item => {
        if(item.quantity > item.packing) {
            const threshold = Math.ceil(item.quantity / item.packing);
            for(let i = 0; i < threshold; i += 1) {
                packages.push({
                    items: [{
                        ...item,
                        quantity: (i + 1) !== threshold ? item.packing : item.quantity - (i * item.packing),
                    }],
                });
            }
        } else {
            packages.push({ items: [{ ...item }] });
        }
    });
    // console.log('Order packages', packages);
    return packages;
}

export function getParcels(method, items) {
    if(!method.parcelGroup) {
        // console.log('getParcels ::: Method without parcel grouping');
        return [[{ items }]];
    }

    const parcels = [];
    const orderPackages = getPackages(items);

    for(let i = 0; i < Math.ceil(orderPackages.length / method.parcelGroup); i += 1) {
        parcels.push([...orderPackages.slice(i * method.parcelGroup, (i + 1) * method.parcelGroup)]);
    }

    // console.log('orderParcels', parcels);
    return parcels;
}

// export function isCartValid(cart, user = null, userGroup = null, orders = []) {
//     const removeConditionsVouchers = (cart.vouchers || []).filter(voucher => checkVoucherConditions(voucher, user, userGroup, orders, cart));
//     const userGroupConditions = ((userGroup || {}).conditions || []).filter(userGroupCondition => !removeConditionsVouchers.find(voucher => voucher.actions.find(action => action.key === 'conditionRemover' && action.value === userGroupCondition.key)));
//     console.log('isCartValid ::: User group conditions applied', (userGroup.conditions || []), userGroupConditions);
//     return (!userGroup || userGroupConditions.every(condition => isConditionChecked(condition, cart.items))) && getTotalQuantity(cart.items) > 0 && ((cart.totals || {}).totalTTC || 0) > 0;
// }

// export function getCartShippingValue(store) {
//     const shipping = getCartShipping(store);
//     return getShippingFee(shipping.address, shipping.method, getCartItems(store), getLoggedUser(store));
// }

export function getCartSubscription(store) {
    return store.cart.subscription;
}

export function getTotalWeight(items, products = null) {
    return getPriceFromCent((items || []).filter(item => item.status === 'ordered').reduce((total, item) => {
        const product = false && products && products.length ? products.find(product => product._id === item.productId) : null;
        return total + getPriceCent(item.quantity * (product && !item.forceUseItemData ? product.weight : item.weight));
    }, 0));
}

export function getTotalQuantity(items, ignoreStatus = false) {
    return getPriceFromCent((items || []).filter(item => ignoreStatus || item.status === 'ordered').reduce((quantity, item) => quantity + getPriceCent((item && item.quantity * ((item.items || []).length ? item.items.reduce((packItemTotal, packItem) => packItemTotal + packItem.quantity, 0) : 1)) || 0), 0));
}

export function getSubtotal(items, products = null, includingTax = true) {
    return getPriceFromCent(getPriceCent((items || []).filter(item => item.status === 'ordered').reduce((total, item) => {
        const priceKey = includingTax ? 'priceTTC' : 'priceHT';
        const product = false && products && products.length ? products.find(product => product._id === item.productId) : null;
        return total + (item.quantity * (product && !item.forceUseItemData ? product[priceKey] : item[priceKey]) || 0);
    }, 0)));
}

export function getTotalTax(items, products = null, taxRate = null, user = null) {
    if(items && items.length && !getUserOption(user, 'exemptVAT')) {
        return formatPrice(
            items
                .filter(item => item.status === 'ordered')
                .map(item => ({
                    ...item,
                    ...((false && !item.forceUseItemData && products && products.length && products.find(product => product._id === item.productId)) || {}),
                }))
                .filter(item => parseFloat(item.taxRate) === parseFloat(taxRate) || taxRate === null)
                .reduce((subtotal, item) => {
                    const lineTotalTTC = item.priceTTC * item.quantity;
                    const lineTotalHT = lineTotalTTC / (1 + parseFloat(item.taxRate));
                    const lineTotalTax = lineTotalTTC - lineTotalHT;
                    return subtotal + lineTotalTax;
                }, 0),
        );
    }
    items && items.length && console.error('TAX set to 0!', items, products, taxRate, getUserOption(user, 'exemptVAT') && 'TVA exempted');
    return 0;
}

export function isConditionPercentValue(condition) {
    return ((condition || {}).value || '').indexOf('%') !== -1;
}

export function getConditionValue(condition) {
    let conditionValue = parseFloat(condition.value);
    if(isConditionPercentValue(condition)) {
        conditionValue = parseFloat(condition.value.replace('%', ''));
    }
    return conditionValue;
}

export function getConditionValueToCheck(condition, items) {
    let valueToCheck = 0;

   if(condition.key === 'weight') { // check order total weight for all suborderitems
       valueToCheck = getTotalWeight(items);
   } else if(condition.key === 'subtotal') { // check order sub total for all suborderitems
       valueToCheck = getSubtotal(items);
   } else if(condition.key === 'packFill') { // check pack filling
       valueToCheck = getItemsPackFill(items, isConditionPercentValue(condition));
   } else if(condition.key === 'packCount') {
       valueToCheck = getPackages(items).length;
   } else {
       console.error('ConditionKeyMissing');
   }
   return valueToCheck;
}

export function isConditionChecked(condition, items, exceptions = []) {
    // console.log('isConditionChecked ::: Start', condition, items, exceptions);

    if(exceptions && exceptions.includes(condition.key)) {
        // console.log('isConditionChecked ::: Allowed by exceptions');
        return true;
    }

    const conditionValue = getConditionValue(condition);
    const valueToCheck = getConditionValueToCheck(condition, items);

    // console.log('isConditionChecked ::: Check', condition.key, `${valueToCheck}${isConditionPercentValue(condition) ? '%' : ''} ${condition.limit === 'min' ? '>' : '<'}${condition.tolerance === 'exclusive' ? '' : '='} ${conditionValue}${isConditionPercentValue(condition) ? '%' : ''}`);
    if(condition.limit === 'min') {
        if(condition.tolerance === 'exclusive') {
            return valueToCheck > conditionValue;
        }
        // inclusive
        return valueToCheck >= conditionValue;
    }
    // max
    if(condition.tolerance === 'exclusive') {
        return valueToCheck < conditionValue;
    }
    // inclusive
    return valueToCheck <= conditionValue;
}

export function getConditionCheckPercentage(condition, items) {
    return (getConditionValueToCheck(condition, items) * 100) / getConditionValue(condition);
}

export function formatAnalyticsItems(items) {
    const getCategories = item => {
        const categories = {};
        (item.categories || []).forEach((category, index) => {
            let categoryFieldName = 'item_category';
            if(index > 0) {
                categoryFieldName += index;
            }
            categories[categoryFieldName] = category;
        });
        return categories;
    };
    return (items || []).map(item => ({
        item_id: item.slug || slugify(item.name),
        item_name: item.name,
        price: formatPrice(parseFloat(item.priceTTC)),
        quantity: parseInt(item.quantity, 10),
        item_brand: 'Keesbo',
        ...getCategories(item),
    }));
}

export function getAvailableConditionKeys() {
    return ['weight', 'subtotal', 'packFill', 'packCount'];
}

// Actions
export function setCartItem(item) {
    return {
        type: SET_ITEM,
        item,
    };
}

export function removeItem(item) {
    return {
        type: REMOVE_ITEM,
        item,
    };
}

export function removeItems() {
    return {
        type: REMOVE_ITEMS,
    };
}

export function clearCart() {
    return {
        type: CLEAR_CART,
    };
}

export function setShippingAddress(address = {}) {
    return {
        type: SET_SHIPPING_ADDRESS,
        address,
    };
}

export function setShippingMethod(method = {}) {
    return {
        type: SET_SHIPPING_METHOD,
        method,
    };
}

export function setInvoiceAddress(address = {}) {
    return {
        type: SET_INVOICE_ADDRESS,
        address,
    };
}

export function setCartOption(key, value) {
    return {
        type: SET_CART_OPTION,
        key,
        value,
    };
}

export function setCartMultiorder(parentUser, parentOrder) {
    return {
        type: SET_CART_MULTIORDER,
        parentUser,
        parentOrder,
    };
}

export function clearCartMultiorder() {
    return {
        type: CLEAR_CART_MULTIORDER,
    };
}

export function addMultiorderInvitation(parentUser, parentUserGroup, parentOrder) {
    return addInvitation({
        type: 'multiorder',
        id: `multiorder-${(parentUser || {})._id}-${(parentOrder || {})._id}`,
        user: parentUser,
        userGroup: parentUserGroup,
        order: parentOrder,
    });
}

export function addInvitation(invitation) {
    return {
        type: ADD_INVITATION,
        invitation,
    };
}

export function removeInvitation(invitationId) {
    return {
        type: REMOVE_INVITATION,
        invitationId,
    };
}

export function setCartNote(note) {
    return {
        type: ADD_CART_NOTE,
        note,
    };
}

export function setCartSubscription(periodUnit, periodValue, maxRepetition) {
    return {
        type: SET_CART_SUBSCRIPTION,
        subscription: {
            periodUnit,
            periodValue,
			maxRepetition,
			isActive: true,
        },
    };
}

export function removeCartSubscription() {
    return {
        type: REMOVE_CART_SUBSCRIPTION,
    };
}

export function setCartSubscriptionActive(isActive = true) {
	return isActive
		? {
			type: SET_CART_SUBSCRIPTION,
			subscription: {
				isActive: true,
			},
		} : removeCartSubscription();
}
