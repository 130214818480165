import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
// import { Tooltip } from 'react-bootstrap';
import Icon from '../../../../components/Content/Icon.js';

import { formatPrice, getPriceCent, getPriceFromCent } from '../../../../util/price.js';

import { defaultCurrency, getCurrencySymbol } from '../../CurrencyActions.js';

class Price extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            tooltipOpen: false,
        };
    }

    getUniqueId() {
        return `tooltip-${Math.floor((Math.random() * 1000) + 1)}`;
    }

    getRate() {
        return this.props.currency.rates[this.props.currency.currency] || 1;
    }

    getConvertedPrice() {
        if(this.props.currency.currency === defaultCurrency) {
            return formatPrice(this.props.amount);
        }
        const rate = this.getRate();
        return formatPrice(this.props.amount * rate);
        // Default to fix undefined price in renderPrice
        // return this.props.amount || 0;
    }

    getCentSeparator() {
        switch(this.props.currency.currency) {
            case 'GBP':
            case 'CHF':
            case 'SEK':
                return '.';

            default:
                return ',';
        }
    }

    isPrefixed() {
        switch(this.props.currency.currency) {
            case 'GBP':
            case 'CHF':
                return true;

            default:
                return false;
        }
    }

    isSymbolDecimalSeparator() {
        switch(this.props.currency.currency) {
            case 'EUR':
                return true;

            default:
                return false;
        }
    }

    toggle = () => {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen,
        });
    };

    renderPrice() {
        const price = this.getConvertedPrice();
        const priceText = price ? price.toString().split('.') : ['0', ''];
        const plainPrice = priceText[0];
        let cents = priceText[1] || '';
        cents = cents.toString();
        if(cents < 10 && cents.length === 1) {
            cents = `${cents}0`;
        // } else if(cents >= 10 && cents.length == 1) {
        //     cents = `${cents}0`;
        }
        const separator = this.isSymbolDecimalSeparator() ? getCurrencySymbol(this.props.currency.currency) : this.getCentSeparator();

        return (
            <Fragment>
                {plainPrice}{cents ? (this.props.formatPrice ? <Fragment>{separator}<sup>{cents}</sup></Fragment> : `${separator}${cents}`) : (this.isSymbolDecimalSeparator() ? getCurrencySymbol(this.props.currency.currency) : '')}
            </Fragment>
        );
    }

    render() {
        // const uniqueId = this.getUniqueId();
        return (
            <span className="text-nowrap">
                {this.isPrefixed() && !this.isSymbolDecimalSeparator() && `${getCurrencySymbol(this.props.currency.currency)} `}
                {this.renderPrice()}
                {!this.isPrefixed() && !this.isSymbolDecimalSeparator() && ` ${getCurrencySymbol(this.props.currency.currency)}`}

                {this.props.currency.currency !== defaultCurrency && this.props.isCalculated && <small> <Icon icon="info" /></small>}
                {/* {false && this.props.currency.currency !== defaultCurrency && this.props.isCalculated && (
                    <Tooltip placement="bottom" isOpen={this.state.tooltipOpen} target={this.getUniqueId()} toggle={this.toggle}>
                        <FormattedMessage id="priceRoundAlertTooltip" defaultMessage="This price may be rounded due to the currency conversation" />
                    </Tooltip>

                )} */}
            </span>
        );
    }
}

function mapStateToProps(store, props) {
    return {
        currency: store.currency,
    };
}
Price.defaultProps = {
    amount: 0,
    isCalculated: false,
    formatPrice: true,
};

Price.propTypes = {
    currency: PropTypes.object.isRequired,
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isCalculated: PropTypes.bool,
    formatPrice: PropTypes.bool,
};

export default connect(mapStateToProps)(Price);
