import React, { Fragment, PureComponent, Suspense, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
// import { Button } from 'react-bootstrap';

// import config from '../../../../config.js';

// import PageContent from '../../components/content/PageContent.js';

import { getCmsPanelRequest, getPanelBySlugAndLanguage, memoizedGetPanelBySlugAndLanguage } from '../../CmsActions.js';
import { getPanelModelViewer } from '../../CmsPanels.js';
// import { getLoggedUser } from '../../../User/UserActions.js';
import Loader from '../../../../components/Loader/Loader.js';
import { getDefaultLanguage } from '../../../Intl/IntlHelper.js';

function CmsPanel({ dispatch, intl, panel, slug = '', locale = getDefaultLanguage(), isTemplate = false, defaultDisplay = null, log = false, ...props }) {
	const [displayEditLink, setDisplayEditLink] = useState(false);

    const setContent = () => {
        log && console.log('CmsPanel::: setContent', slug, locale || intl.locale, isTemplate);
        return !isTemplate && !panel && dispatch(getCmsPanelRequest(slug, locale || intl.locale));
    };

    // useEffect(() => {
	// 	log && console.log('CmsPanel::: Mounted', panel);
    //     !panel && setContent();
	// }, []);

	useEffect(() => {
		log && console.log('CmsPanel::: Updated', slug, locale, intl.locale);
		log && console.log('CmsPanel::: Updated');
		setContent();
	}, [slug, locale, intl.locale]);

    const handleToggleEdit = () => setDisplayEditLink(true);

    const renderPanel = () => {
		log && console.log('CmsPanel::: Panel exists ?', panel, defaultDisplay);
        if(panel && panel.blocks) {
            const ModelComponent = getPanelModelViewer(panel.model);
            return (
					<ModelComponent panel={panel} {...props} />
				// <Suspense fallback={<Loader hideContent />}>
				// 	<ModelComponent panel={panel} {...props} /> {/* eslint-disable-line */}
				// </Suspense>
			);
        }
        if(defaultDisplay) {
            return defaultDisplay;
        }
        if(process.env.NODE_ENV !== 'production') {
            return `CmsPanel ::: TODO ::: ${slug}`;
        }
        log && console.log('CmsPanel::: Panel not found !!', slug);
        return null;
    };

	log && console.log('CmsPanel::: Render', slug);
	// Remove inline onClick function: https://www.codementor.io/blog/react-optimization-5wiwjnf9hj#7-avoid-inline-function-definition-in-the-render-function
	return (
		<Fragment>
			{renderPanel()}
			{/* {false && panel && (user || {}).role === 'admin' && <Button variant="link" as={Link} to={`/fr/console/content/${panel._id}`} className="position-absolute" style={{ top: '0px', right: '-10px' }}>Edit panel</Button>} */}
		</Fragment>
	);
}

function mapStateToProps(store, props) {
    return {
        panel: memoizedGetPanelBySlugAndLanguage(store, props.slug, props.locale || store.intl.locale),
        // user: getLoggedUser(store),
    };
}

CmsPanel.defaultProps = {
    panel: null,
    // user: null,
    locale: '',
    isTemplate: false,
    defaultDisplay: null,
	log: false,
};

CmsPanel.propTypes = {
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    slug: PropTypes.string.isRequired,
    panel: PropTypes.object,
    // user: PropTypes.object,
    locale: PropTypes.string,
    isTemplate: PropTypes.bool,
    defaultDisplay: PropTypes.any,
    log: PropTypes.bool,
};

// export default CmsPanel;
export default connect(mapStateToProps)(injectIntl(CmsPanel));
