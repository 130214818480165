import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';

import { getFile } from '../../../../util/file.js';

import { getContentBlockItemsByName, getContentBlockItemValue, getContentBlockMixedItem } from '../../../Cms/CmsActions.js';

import LazyLoader from '../../../../components/Content/LazyLoader.js';
import HtmlComponent from '../../../../components/Text/HtmlComponent.js';
import GenericSnippet from '../../../App/components/view/GenericSnippet.js';

import PreFooter from '../../../App/components/PreFooter.js';
import ReviewSummary from '../../../Review/components/view/ReviewSummary.js';

import ProductShippingInformation from '../../components/view/ProductShippingInformation.js';
import withRouter from '../../../../components/Router/WithRouter.js';
import PageCover from '../../../Cms/components/view/PageCover.js';
import ProductsListWrapper from '../../components/list/ProductsListWrapper.js';

function CategoryViewPage({ search, page = null }) {
	const currentPageIndex = parseInt(search.get('page'), 10) || 1;

	const { blocks } = page || {};
	const cover = getContentBlockItemValue(blocks, 'cover', 'path');
	const title = getContentBlockItemValue(blocks, 'title');
	const descriptions = getContentBlockItemsByName(blocks, 'description');
	const descriptionsAlt = getContentBlockItemsByName(blocks, 'descriptionAlt');

	// const categoriesPanel = getContentBlockItemValue(blocks, 'categories');
	return (
		<Fragment>
			<div className="top">
				<GenericSnippet
					title={title}
					description={descriptions.map(description => (getContentBlockMixedItem(description, 'text') || {}).text).join(', ')}
					image={cover ? getFile(cover) : ''}
				/>

				<PageCover page={page} />

				{currentPageIndex === 1 && descriptions && descriptions.length > 0 && (
					<div className="py-4 px-2 px-md-0 bg-info">
						<Container>
							{descriptions.map((description, index) => {
								const { text } = getContentBlockMixedItem(description, 'text') || {};
								const image = getContentBlockMixedItem(description, 'image') || {};
								const renderText = () => <HtmlComponent key={index} text={text} useCollapse />;
								const renderImage = () => (
									<LazyLoader>
										{image.path && (
											<picture className="d-none d-md-inline w-100">
												<source src={getFile(image.path, { format: 'webp' })} type="image/webp" />
												<img src={getFile(image.path, { width: 600 })} alt={image.text} className="rounded" loading="lazy" />
											</picture>
										)}
									</LazyLoader>
								);
								if(!image.path) {
									return renderText();
								}
								return (
									<Row key={index} className="py-2">
										<Col xs="12" md={index % 2 === 0 ? 8 : 4}>
											{index % 2 === 0 ? renderText() : renderImage()}
										</Col>
										<Col xs="12" md={index % 2 === 0 ? 4 : 8}>
											{index % 2 === 0 ? renderImage() : renderText()}
										</Col>
									</Row>
								);
							})}
						</Container>
					</div>
				)}

				<ProductsListWrapper page={page} hideTitle={currentPageIndex !== 1} />
			</div>

			<div className="bottom">

				{currentPageIndex === 1 && descriptionsAlt && descriptionsAlt.length > 0 && (
					<div className="py-4 px-2 px-md-0 bg-info bg-texture">
						<Container>
							{descriptionsAlt.map((description, index) => {
								const { text } = getContentBlockMixedItem(description, 'text') || {};
								const image = getContentBlockMixedItem(description, 'image') || {};
								const renderText = () => <HtmlComponent key={index} text={text} />;
								const renderImage = () => (
									<LazyLoader>
										{image.path && <img src={getFile(image.path, { width: 540 })} alt={image.text} className="d-none d-md-inline rounded-sm w-100" loading="lazy" />}
									</LazyLoader>
								);
								if(!image.path) {
									return renderText();
								}
								return (
									<Row key={index} className="py-2">
										<Col xs="12" md="6">
											{index % 2 === 0 ? renderText() : renderImage()}
										</Col>
										<Col xs="12" md="6">
											{index % 2 === 0 ? renderImage() : renderText()}
										</Col>
									</Row>
								);
							})}
						</Container>
					</div>
				)}

				{/* <ReviewSummary hideLogo />

				<ProductShippingInformation />

				<div className="bg-info bg-texture">
					{categoriesPanel && <CmsPanel slug={categoriesPanel || 'categories'} />}
					<PreFooter />
				</div> */}
			</div>

		</Fragment>
	);
}

CategoryViewPage.propTypes = {
    page: PropTypes.object.isRequired,
	search: PropTypes.object.isRequired,
};

export default withRouter(CategoryViewPage, ['search']);
