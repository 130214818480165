// Import Actions
import { SET_AFFILIATIONS, SET_CURRENT_AFFILIATION, REMOVE_AFFILIATION } from './AffiliationActions.js';
import { LOGOUT_USER } from '../User/UserActions.js';
import { getPersistedData, persistData } from '../../util/persistor.js';
import { INIT_PERSISTED_DATA } from '../App/AppActions.js';

// Initial State
export const initialState = {
    data: [],
    currentAffiliation: {},
};

const AffiliationReducer = (state = initialState, action = {}) => {
    switch(action.type) {
        case SET_AFFILIATIONS: {
            const otherAffiliations = state.data.filter(affiliation => !action.affiliations.find(affiliationToSet => affiliationToSet._id === affiliation._id));
            return {
                ...state,
                data: otherAffiliations.concat(action.affiliations),
            };
        }

        case SET_CURRENT_AFFILIATION: {
            const newState = {
                ...state,
                currentAffiliation: action.currentAffiliation,
            };
            persistData('affiliation', newState);
            return newState;
        }

        case REMOVE_AFFILIATION: {
            return {
                ...state,
                data: state.data.filter(affiliation => affiliation._id !== action.affiliationId),
            };
        }

        case LOGOUT_USER:
            persistData('affiliation', initialState);
            return initialState;

        case INIT_PERSISTED_DATA:
            return getPersistedData('affiliation', state);

        default:
            return state;
    }
};

export default AffiliationReducer;
