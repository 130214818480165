// If you add env vars => Add it to initEnvVars function in server/api/app/app.controller.js

const envVars = process.env.NODE_ENV === 'development' || process.env.BASE_URL
    ? process.env
    : ((typeof window !== 'undefined' && window.env_vars) || process.env || {});

const config = {
    version: envVars.APP_VERSION || '',
    url: envVars.BASE_URL || 'http://localhost:8000/',
    api: envVars.API_URL || 'http://localhost:8000/api', // 'https://dev.directos.eu',
    ws: envVars.WEBSOCKET_URL || '',
    port: envVars.PORT || 8000,
    application: {
        name: 'Directos.eu',
        email: 'contact@directos.eu',
        phone: '+33.6.81.46.04.49',
        seo: {
            defaultTitle: '',
        },
        options: {
        },
        discount: {
        },
        rating: {
            max: 5,
        },
        order: {
            minimum: 20,
        },
        payment: {
            transfer: {
                bankName: 'Société Générale',
                iban: 'FR76 3000 3032 8700 0200 5741 282',
                bic: 'SOGEFRPP',
            },
        },
        security: {
            secureApiCall: false,
            secureApiCallHeader: 'Custom-Soc', // SOC: Secure Origin Checker
        },
    },
    aws: {
        s3: {
            region: 'eu-west-3',
            bucket: envVars.S3_BUCKET || 'dev.directos.eu',
        },
    },
    facebook: {
    },
    google: {
        analytics: {
            trackingCode: envVars.GOOGLE_ANALYTICS_TRACKING_CODE || '',
            trackingCodeGA4: envVars.GOOGLE_ANALYTICS_TRACKING_CODE_GA4 || '',
        },
    },
    stripe: {
        publicKey: envVars.STRIPE_PUBLIC_KEY || 'pk_test_DppRmk7CNbN3Rvfd6ZnLn1UD',
        acceptedMethods: ['Visa', 'MasterCard', 'American Express'],
    },
    paypal: {
        clientId: envVars.PAYPAL_CLIENT_ID || 'AdcrDtBqOcjWWbXDpaB-rVjmrqT-F-6y7LDVx6cboHjrWEnPufVs-KKJavmkKiifJy_V7-60v1wfpIhm',
    },
    mondialrelay: {
        clientId: 'CC210E6G',
    },
    bugsnag: {
        key: envVars.BUGSNAG_KEY || '',
    },
    selldorado: {
        id: '660024808',
    },
};
// console.log(config);
false && process.env.NODE_ENV === 'development' && console.log('Client config', config);
// export default config;
module.exports = config;
