import React, { lazy } from 'react';
// Blocks
import PanelDefaultModel from './components/model/panels/PanelDefaultModel.js';
import PanelTextModel from './components/model/panels/PanelTextModel.js';
import PanelFooterLinksModel from '../App/components/view/PanelFooterLinksModel.js';
import PanelMainMenuModel from '../App/components/menu/PanelMainMenuModel.js';
import PanelCategoryModel from '../Product/components/view/PanelCategoryModel.js';
import PanelRecommandationModel from './components/model/panels/PanelRecommandationModel.js';
import PanelShippingModel from './components/model/panels/PanelShippingModel.js';
import PanelVoucherPopinModel from './components/model/panels/PanelVoucherPopinModel.js';
import PanelSliderModel from './components/model/panels/PanelSliderModel.js';

// const PanelDefault = lazy(() => import('./components/model/panels/PanelDefault'));
// const PanelText = lazy(() => import('./components/model/panels/PanelText'));
// const PanelFooterLinks = lazy(() => import('../App/components/view/PanelFooterLinks'));
// const PanelMainMenu = lazy(() => import('../App/components/menu/PanelMainMenu'));
// const PanelCategory = lazy(() => import('../Product/components/view/PanelCategory'));
// const PanelRecommandation = lazy(() => import('./components/model/panels/PanelRecommandation'));
// const PanelShipping = lazy(() => import('./components/model/panels/PanelShipping'));
// const PanelVoucherPopin = lazy(() => import('./components/model/panels/PanelVoucherPopin'));
// const PanelSlider = lazy(() => import('./components/model/panels/PanelSlider'));
import PanelDefault from './components/model/panels/PanelDefault';
import PanelText from './components/model/panels/PanelText';
import PanelFooterLinks from '../App/components/view/PanelFooterLinks';
import PanelMainMenu from '../App/components/menu/PanelMainMenu';
import PanelCategory from '../Product/components/view/PanelCategory';
import PanelRecommandation from './components/model/panels/PanelRecommandation';
import PanelShipping from './components/model/panels/PanelShipping';
import PanelVoucherPopin from './components/model/panels/PanelVoucherPopin';
import PanelSlider from './components/model/panels/PanelSlider';

export const CMS_PANEL_MODELS = [
    PanelDefaultModel,
    PanelTextModel,
    PanelSliderModel,
    PanelMainMenuModel,
    PanelFooterLinksModel,
    PanelCategoryModel,
    PanelRecommandationModel,
    PanelShippingModel,
    PanelVoucherPopinModel,
];
export const CMS_PANEL_MODEL_DEFAULT = PanelDefault;

export function getPanelModelViewer(model) {
    switch(model) {
        // Add custom model page
        // case 'xyz':
        //     return <PageXyz page={this.props.page} />;
        case 'mainMenu':
            // return import('../App/components/menu/PanelMainMenu');
			return PanelMainMenu;

        case 'footerLinks':
            return PanelFooterLinks;

        case 'category':
            return PanelCategory;

        case 'recommandation':
            return PanelRecommandation;

        case 'voucherPopin':
            return PanelVoucherPopin;

        case 'shipping':
            return PanelShipping;

        case 'slider':
            return PanelSlider;

        case 'text':
            return PanelText;

        case 'default':
        default:
            return PanelDefault;
    }
}
