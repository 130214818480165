import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { getContentBlockItemValue } from '../../CmsActions';
import { getFile } from '../../../../util/file';

function PageCover({ page, appIsMobile = false, coverKey = 'cover', defaultCover = null, defaultStyles = {}, beforeTitle = null, afterTitle = null }) {
	const cover = getContentBlockItemValue(page?.blocks, coverKey, 'path', { useForMobile: appIsMobile }) || getContentBlockItemValue(page?.blocks, coverKey, 'path') || defaultCover;
	const picto = getContentBlockItemValue(page?.blocks, 'picto', 'path');

	const title = getContentBlockItemValue(page?.blocks, 'title') || page.title;

	const coverStyles = {
		...defaultStyles,
		backgroundImage: cover ? `url(${getFile(cover)})` : 'none',
	};
	// if(!cover) {
	// 	coverStyles.height = 'auto';
	// }

	return (
		<Fragment>
			<Helmet>
				<body data-header-contrast={cover ? 'true' : 'false'} />
			</Helmet>
			<div className={classNames(/* 'd-block', 'd-lg-none', */ cover && 'header-cover', 'page-wrapper', 'bg-info')} style={coverStyles}>
				{beforeTitle}
				<Container className="ms-3 ms-sm-1 mx-md-auto mt-xl-5 pt-md-3 pt-lg-4">
					{picto && <img src={getFile(picto)} alt={picto.split('.').shift()} width={appIsMobile ? 50 : 75} height={appIsMobile ? 50 : 75} loading="lazy" />}
					<h1 className={classNames('mt-md-2 mb-2 w-100 w-md-50 border-half-danger', cover && 'text-white')}>
						{title}
					</h1>
					{afterTitle}
				</Container>
			</div>
		</Fragment>
	);
}

PageCover.propTypes = {
	page: PropTypes.object.isRequired,
	appIsMobile: PropTypes.bool,
	coverKey: PropTypes.string,
	defaultCover: PropTypes.string,
	defaultStyles: PropTypes.object,
	beforeTitle: PropTypes.element.isRequired,
	afterTitle: PropTypes.element.isRequired,
};

const mapStateToProps = (store, props) => ({
	appIsMobile: store.app.isMobile,
});

export default connect(mapStateToProps)(PageCover);
