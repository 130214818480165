import { storagePersistent } from '../../util/storage.js';
import { persistData, getPersistedData, clearPersistedData } from '../../util/persistor.js';

// Import Actions
import { SET_USER_GROUPS, SET_USER_GROUP, SET_USER_GROUP_FROM_PARENT, USER_GROUP_STORAGE_KEY } from './UserGroupActions.js';
import { LOGOUT_USER } from './UserActions.js';
import { INIT_PERSISTED_DATA } from '../App/AppActions.js';

// Initial State
export const initialState = {
    data: [],
    userGroup: null,
    isFromParent: false,
};

const UserReducer = (state = initialState, action = {}) => {
    switch(action.type) {
        case SET_USER_GROUPS: {
            return {
                ...state,
                data: action.groups,
            };
        }

        case SET_USER_GROUP: {
            const newState = {
                ...state,
                userGroup: state.isFromParent ? state.userGroup : action.group,
            };
            newState.userGroup.isDefault ? clearPersistedData('userGroup') : persistData('userGroup', newState);
            // persistData(USER_GROUP_STORAGE_KEY, action.userGroup.identifier, false);
            return newState;
        }

        case SET_USER_GROUP_FROM_PARENT: {
            const newState = {
                ...state,
                isFromParent: action.isFromParent || false,
            };
            persistData('userGroup', newState);
            return newState;
        }

        case LOGOUT_USER: {
            // clearPersistedData(USER_GROUP_STORAGE_KEY, false);
            clearPersistedData('userGroup');
            return {
                ...state,
                userGroup: null,
            };
        }

        case INIT_PERSISTED_DATA:
            return getPersistedData('userGroup', state);

        default:
            return state;
    }
};

export default UserReducer;
