import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Row, Col, ButtonGroup, Button, Dropdown } from 'react-bootstrap';

import { capitalizeFirstLetter } from '../../../../util/text.js';

import { getProductPrice, getProductOption } from '../../ProductActions.js';
import { getUserGroup, USER_GROUP_DEFAULT } from '../../../User/UserGroupActions.js';
import { getTranslation } from '../../../Intl/IntlHelper.js';

import withRouter from '../../../../components/Router/WithRouter.js';
import Icon from '../../../../components/Content/Icon.js';
import FormattedMessageConditionnal from '../../../Intl/components/Translate/FormattedMessageConditionnal.js';
import ProductListItem from './ProductListItem.js';
import CmsPanel from '../../../Cms/components/content/CmsPanel.js';

const PRODUCT_LIST_COUNT = 10;

function ProductList({ intl, navigate, search, products = [], promotions = [], userGroup = null, categoryOrder = [], hideSorter = false, hidePager = false }) {
    // const [sorterType, setSorterType] = useState('asc');
    // const [sorterKey, setSorterKey] = useState('category');
	const [itemsPerPage, setItemsPerPage] = useState(PRODUCT_LIST_COUNT);

	const page = parseInt(search.get('page'), 10) || 1;
	const sorterType = search.get('sort') || 'asc';
	const sorterKey = search.get('sortBy') || 'category';
    // const handleSort = event => { setSorterKey(event.target.getAttribute('data-key') || event.target.parentNode.getAttribute('data-key')); setSorterType(event.target.getAttribute('data-type') || event.target.parentNode.getAttribute('data-type')); };

	products = []
		.concat(
			(categoryOrder || []).length && sorterKey === 'category'
			? (categoryOrder || []).map(productId => products.find(product => product._id === productId)).filter(product => product)
			: [],
		)
		.concat(products
			.filter(product => (
					sorterKey !== 'category'
					|| !(categoryOrder || []).length
					|| !categoryOrder.includes(product._id)
				)
				&& (
					!(getProductOption(product, 'visibleGroups') || []).length
					|| getProductOption(product, 'visibleGroups').includes((userGroup || {}).identifier)
				))
			.sort((productA, productB) => {
				switch(sorterKey) {
					case 'weight':
						return (sorterType === 'asc' ? productA : productB).weight - (sorterType === 'asc' ? productB : productA).weight;

					case 'price':
						return getProductPrice(sorterType === 'asc' ? productA : productB, 1, userGroup) - getProductPrice(sorterType === 'asc' ? productB : productA, 1, userGroup);

					case 'name':
					default:
						return getTranslation(sorterType === 'asc' ? productA : productB, 'name', intl.locale).localeCompare(getTranslation(sorterType === 'asc' ? productB : productA, 'name', intl.locale));
				}
			}));

	useEffect(() => {
		if(page > Math.ceil(products.length / itemsPerPage)) {
			navigate('?page=1');
		} else if(page > 1) {
			// window.document.getElementById('products-list')?.scrollIntoView({ behavior: 'instant', block: 'start' });
		}
	}, [page, sorterType, sorterKey]);

    const renderProducts = () => {
        let promotionCount = 0;

        const renderPromotion = () => {
            if((userGroup || {}).identifier === USER_GROUP_DEFAULT || process.env.NODE_ENV === 'development') {
                const promotion = promotions[promotionCount].text;
                promotionCount += 1;
                return (
                    <Col xs="12" md="6" lg="4" className="h-100">
                        <div className="h-100 mb-3 px-3 py-4 bg-warning rounded" style={{ minHeight: '370px' }}>
                            <CmsPanel slug={promotion} />
                        </div>
                    </Col>
                );
            }
            return null;
        };

		return products.slice((page - 1) * itemsPerPage, page * itemsPerPage)
			.map((product, index, array) => (
				<Fragment key={product._id}>
					<Col xs="12" md="6" lg="4">
						<ProductListItem product={product} />
					</Col>
					{promotions[promotionCount] && index > 0 && index % Math.ceil(array.length / (promotions.length + 1)) === 0 && renderPromotion()}
				</Fragment>
			));
    };

    const renderListPagerAndSorter = (isAlt = false) => {
        if(!products.length) {
            return null;
        }
		const queryParameters = [''].concat(search.get('sortBy') ? `sortBy=${search.get('sortBy')}` : []).concat(search.get('sort') ? `sort=${search.get('sort')}` : []).join('&');
        return (
			<div className={classnames('d-md-flex', 'justify-content-between', 'border-light', isAlt ? ['mt-2'] : ['mb-2'])}>
				{!hideSorter && (
					<div className={classnames('text-center', 'text-lg-end')}>
						<Dropdown>
							<Dropdown.Toggle variant="outline-light" size="sm" className="category-sorter">
								<FormattedMessageConditionnal id={`categorySorter${capitalizeFirstLetter(sorterKey)}Action`} defaultMessage={`Sort by ${capitalizeFirstLetter(sorterKey)}`} />
							</Dropdown.Toggle>
							<Dropdown.Menu>
								{['name', 'weight', 'price'].map(availableSorterKey => {
									return ['asc', 'desc'].map(availableSorterType => {
										if(availableSorterKey === sorterKey && availableSorterType === sorterType) {
											return null;
										}
										return (
											<Dropdown.Item key={`${availableSorterKey}-${availableSorterType}`} data-key={availableSorterKey} data-type={availableSorterType} as="a" href={`?sortBy=${availableSorterKey}&sort=${availableSorterType}`}>
												<FormattedMessageConditionnal id={`categorySorter${capitalizeFirstLetter(availableSorterKey)}Action`} defaultMessage={`Sort by ${capitalizeFirstLetter(availableSorterKey)} ${availableSorterType}`} />
												{' '}<Icon icon={availableSorterType === 'asc' ? 'arrow-long-down' : 'arrow-long-up'} />
											</Dropdown.Item>
										);
									});
								})}
							</Dropdown.Menu>
						</Dropdown>
						{/* <Dropdown>
							<Dropdown.Toggle variant="link" size="sm" as="span" className="p-0" suppressHydrationWarning>
								{getCurrencySymbol(currencyData.currency)}
							</Dropdown.Toggle>
							<Dropdown.Menu className="position-absolute text-center" style={{ left: '-200%' }} suppressHydrationWarning>{currenciesNodes}</Dropdown.Menu>
						</Dropdown> */}
					</div>
				)}
				{!hidePager && products.length > itemsPerPage && (
					<div>
						<ButtonGroup size="lg" className="w-100 my-5 my-md-0 border-light border-1 category-pager">
							<Button variant="outline-danger" as="a" href={page > 1 ? `?page=${page - 1}${queryParameters}` : ''} disabled={page <= 1} rel="prev" className="border-0 px-2">{'< '}</Button>
							{products.length > 0 && new Array(Math.ceil(products.length / itemsPerPage)).fill(1).map((v, index) => <Button key={index} variant={index + 1 === page ? 'danger' : 'outline-danger'} as="a" href={`?page=${index + 1}${queryParameters}`} className={classnames('mx-1', 'border', 'rounded', 'rounded-bottom-4', page === index + 1 && ['bg-danger'])}>{index + 1}</Button>)}
							<Button variant="outline-danger" as="a" href={products.length - (page * itemsPerPage) > 0 ? `?page=${page + 1}${queryParameters}` : ''} disabled={products.length - (page * itemsPerPage) <= 0} rel="next" className="border-0 px-2">{' >'}</Button>
						</ButtonGroup>
					</div>
				)}
			</div>
        );
    };

    return (
        <div id="products-list">
			{renderListPagerAndSorter()}

            <Row className="mb-3">
                {renderProducts()}
            </Row>

			{renderListPagerAndSorter(true)}
        </div>
    );
}

function mapStateToProps(store, props) {
    return {
        userGroup: getUserGroup(store),
    };
}

ProductList.propTypes = {
    intl: PropTypes.object.isRequired,
	navigate: PropTypes.func.isRequired,
	search: PropTypes.object.isRequired,
    products: PropTypes.arrayOf(PropTypes.object),
    userGroup: PropTypes.object,
    promotions: PropTypes.arrayOf(PropTypes.object),
    categoryOrder: PropTypes.arrayOf(PropTypes.string),
    hideSorter: PropTypes.bool,
    hidePager: PropTypes.bool,
};

export default connect(mapStateToProps)(withRouter(injectIntl(ProductList), ['navigate', 'search']));
