export default {
    type: 'panel',
    name: 'category',
    blocks: [
        {
            name: 'categories',
            type: 'mixed',
            blocks: [
                {
                    name: 'image',
                    type: 'image',
                    max: 1,
                },
                {
                    name: 'picto',
                    type: 'image',
                    max: 1,
                },
                {
                    name: 'link',
                    type: 'link',
                    max: 1,
                },
            ],
        },
        {
            name: 'content',
            type: 'html',
            max: 1,
        },
    ],
};
