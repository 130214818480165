import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Row, Col, Card } from 'react-bootstrap';

import { getFile } from '../../../../util/file.js';

import { getContentBlockItemsByName, getContentBlockMixedItem, getContentBlockItemValue } from '../../../Cms/CmsActions.js';
import { getUserGroup, USER_GROUP_DEFAULT } from '../../../User/UserGroupActions.js';

import withRouter from '../../../../components/Router/WithRouter.js';
import Icon from '../../../../components/Content/Icon.js';
import LazyLoader from '../../../../components/Content/LazyLoader.js';
import AppLogo from '../../../App/components/view/AppLogo.js';
import HtmlComponent from '../../../../components/Text/HtmlComponent.js';
import PredictiveLink from '../../../Cms/components/view/PredictiveLink.js';

// configReveal({ ssrFadeout: true });

function PanelCategory({ navigate, panel, userGroup = null }) {
    const { blocks } = panel || {};
    const categories = getContentBlockItemsByName(blocks, 'categories');
    const content = getContentBlockItemValue(blocks, 'content');

    if(categories && categories.length > 0) {
        return (
            <div className="bg-white">
                <Container>
                    <div className="mb-3 text-center">
                        <span className="h2 d-inline-block border-half-danger text-center"><FormattedMessage id="appIntroText3" defaultMessage="Our selection" /></span>
                    </div>
                    <Row>
                        {categories.map((category, index) => {
                            const image = getContentBlockMixedItem(category, 'image');
                            const picto = getContentBlockMixedItem(category, 'picto');
                            const link = getContentBlockMixedItem(category, 'link');

                            const handleGoToCategory = event => link.path && navigate(link.path);
                            return (
                                <Col key={index} xs="12" md="6">
                                    <Card className="overflow-hidden mx-2 mx-md-0 mb-4 pb-1" style={{ height: 'auto', minHeight: '200px' }}> {/* onClick={handleGoToCategory} */}
                                        {image && (
                                            <LazyLoader>
                                                <picture>
                                                    <source media="(min-width: 800px)" srcSet={getFile(image.path)} className="card-img" width="600" height="400" loading="lazy" />
                                                    <img src={getFile(image.path)} alt={image.text} className="card-img" width="300" height="200" loading="lazy" />
                                                </picture>
                                            </LazyLoader>
                                        )}
                                        <Card.ImgOverlay>
                                            <a href={link.path} className="d-block position-absolute text-white" style={{ bottom: '10%', left: '10%', width: '80%' }}>
                                                {picto && picto.path && (
                                                    <Fragment>
                                                        <img src={getFile(picto.path)} alt={picto.text} width="75" height="75" className="d-none d-md-inline mb-3" loading="lazy" />
                                                        <img src={getFile(picto.path)} alt={picto.text} width="40" height="40" className="d-inline d-md-none mb-3" loading="lazy" />
                                                    </Fragment>
                                                )}
                                                <br />
                                                {link && (
                                                    <div className="position-relative">
                                                        <span className={classnames('d-inline-block', 'pe-2', 'pe-md-0', 'w-lg-75', 'w-xl-50', 'h3', 'border-half-danger', (!picto || !picto.path) && 'mt-5')}>{link.text}</span>
                                                        <div className="mb-3 position-absolute h5 w-25 text-end" style={{ bottom: 0, right: '-10px' }}>
                                                            <div className="d-flex justify-content-between">
                                                                <span><span className="d-none d-lg-inline-block me-1" style={{ lineHeight: '1.1rem' }}><FormattedMessage id="productsView" defaultMessage="View products" /> </span></span>
                                                                <Icon icon="chevron-right" size="2x" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </a>
                                        </Card.ImgOverlay>
                                    </Card>
                                </Col>
                            );
                        })}
                        {categories.length % 2 !== 0 && (
                            <Col className={classnames(!content && ['d-none', 'd-md-block'])}>
                                {
                                    content && ((userGroup || {}).identifier === USER_GROUP_DEFAULT)
                                    ? (
                                        <Card body className="shadow text-center">
                                            <HtmlComponent text={content} />
                                        </Card>
                                    ) : (
                                        <LazyLoader>
                                            <AppLogo suffix="D-fade" className="mw-100 card-image" />
                                        </LazyLoader>
                                    )
                                }
                            </Col>
                        )}
                    </Row>
                </Container>
            </div>
        );
    }
    return null;
}

function mapStateToProps(store, props) {
    return {
        userGroup: getUserGroup(store),
    };
}

PanelCategory.propTypes = {
    navigate: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
    panel: PropTypes.object.isRequired,
    userGroup: PropTypes.object,
};

export default connect(mapStateToProps)(withRouter(injectIntl(PanelCategory)));
