import React, { startTransition, useState, lazy, Suspense, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import CookieConsent from 'react-cookie-consent';

import config from '../../../../config.js';

import { getLoggedUser, getUserRolesForConsole } from '../../../User/UserActions.js';
import { USER_GROUP_DEFAULT, getUserGroup } from '../../../User/UserGroupActions.js';

import Icon from '../../../../components/Content/Icon.js';
import LazyLoader from '../../../../components/Content/LazyLoader.js';

import CmsPanel from '../../../Cms/components/content/CmsPanel.js';

// Import Style
import { getCountryFromLanguage, getEnabledLanguages } from '../../../Intl/IntlHelper.js';
import Flag from '../../../../components/Flag/Flag.js';

import StarRating from '../../../Review/components/view/StarRating.js';
import ExitIntent from '../view/ExitIntent.js';
import UserAdminLoginSwitcher from '../../../User/components/admin/UserAdminLoginSwitcher.js';
import VoucherPopin from '../../../Voucher/components/view/VoucherPopin.js';
import InstantRegister from '../../../User/components/auth/InstantRegister.js';
import NewsletterPopin from '../../../User/components/newsletter/NewsletterPopin.js';
import MultiOrderPopin from '../../../Order/components/view/MultiOrderPopin.js';
import { getConfig } from '../../../Config/ConfigActions.js';
import PredictiveLink from '../../../Cms/components/view/PredictiveLink.js';

function Footer({ locale, user = null, userGroup = null, isCookieConsentCustomActive = false }) {
    const [isMounted, mount] = useState(false);
    const [isScrolling, setIsScrolling] = useState(false);
    // startTransition(() => {
    //     mount(true);
    // });
	useEffect(() => {
		mount(true);
	}, []);

    const handleGoToFB = () => {
        window.open('https://www.facebook.com/directos.eu/', '_blank');
    };

    const handleGoToInstagram = () => {
        window.open('https://www.instagram.com/directos.eu/', '_blank');
    };

    const handleGoToOA = () => {
        window.open('https://offaxis.io', '_blank');
    };

    const handleGoToContact = () => {
        window.open(`/${locale}/page/contact`, '_self');
    };

    return (
        <div className="footer-wrapper pb-5 pb-lg-2">
            <Container>
                <div className="subfooter text-center text-sm-start">
                    <Row className="m-3">
                        <Col xs="12" sm="6" md="3" className="mb-3">
                            <LazyLoader>
                                <span className="d-block text-uppercase"><FormattedMessage id="footerPaymentSecureInfo" defaultMessage="Secured payment guarantee" /></span>
                                <picture>
                                    <source srcSet={`${config.url}assets/images/secure-payments.webp`} type="image/webp" loading="lazy" />
                                    <img src={`${config.url}assets/images/secure-payments.png`} width="200" height="200" alt="Secured payment on Directos.eu" loading="lazy" />
                                </picture>
                            </LazyLoader>
                        </Col>
                        <Col xs="12" sm="6" md="3" className="mb-3">
                            <LazyLoader>
                                <span className="d-block text-uppercase"><FormattedMessage id="footerShippingDeliveryInfo" defaultMessage="Delivery all over Europe" loading="lazy" /></span>
                                <picture>
                                    <source srcSet={`${config.url}assets/images/shippings.webp`} type="image/webp" loading="lazy" />
                                    <img src={`${config.url}assets/images/shippings.png`} width="200" height="120" alt="Delivery services" />
                                </picture>
                            </LazyLoader>
                        </Col>
                        <Col xs="12" sm="6" md="3" className="mb-3">
                            <span className="d-block text-uppercase"><FormattedMessage id="footerAboutUsSection" defaultMessage="About us" /></span>
                            <CmsPanel slug="footer-links" isTemplate locale={locale} />
                        </Col>
                        <Col xs="12" sm="6" md="3" className="mb-3">
                            <span className="d-block mb-3 text-uppercase"><FormattedMessage id="footerSocialSection" defaultMessage="Stay tuned!" /></span>
                            <ul className="list-unstyled social-info">
                                <li className="text-center">
                                    {isMounted && <Button variant="link" onClick={handleGoToFB} rel="noreferrer" className="d-inline-block m-0 py-0 px-2 text-white">Facebook</Button>}{' '}
                                    {isMounted && <Button variant="link" onClick={handleGoToInstagram} rel="noreferrer" className="d-inline-block m-0 py-0 px-2 text-white">Instagram</Button>}
                                </li>
                                <li className="mt-2"><Button variant="danger" id="contact-button" className="w-100 rounded-lg px-3" as="span" onClick={handleGoToContact}><Icon icon="mail" /> <FormattedMessage id="pageContactUsLink" defaultMessage="Contact us" /></Button></li>
                            </ul>
                            <LazyLoader>
                                <span className="text-white"><StarRating /></span>
                            </LazyLoader>
                        </Col>
                    </Row>
                </div>
            </Container>
            <div className="subfooter-wrapper px-2 text-center">
                <div>
                    <span className="h5"><FormattedMessage id="appLocalesAltTitle" defaultMessage="Also exists in" /></span> {getEnabledLanguages().map(language => <a key={language} href={`${config.url}${language}/`} className="d-inline-block me-1 px-1"><Flag country={getCountryFromLanguage(language)} /></a>)}
                </div>
                <small className="text-uppercase"><FormattedMessage id="appLegalFooterNotice" defaultMessage="For your health, eat at least five fruits and vegetables a day" /></small><br />
                <small>&copy; {new Date().getFullYear()} &middot; Directos.eu{isMounted && ` - v${config.version}`} - Made by <a href="#" onClick={handleGoToOA} target="_blank" rel="noopener nofollow">OFF<span style={{ color: '#FF0000' }}>/</span>AXIS</a></small>
            </div>

            <UserAdminLoginSwitcher />

			{(!userGroup || userGroup.identifier === USER_GROUP_DEFAULT) && <ExitIntent />}

			<NewsletterPopin />

			<VoucherPopin />

			{!user && <InstantRegister />}

			<MultiOrderPopin />

			{isCookieConsentCustomActive && (
				<CookieConsent
					buttonText={<FormattedMessage id="close" defaultMessage="Close" />}
					buttonClasses="btn btn-outline-primary btn-sm"
					buttonStyle={{}}
				>
					<small><FormattedMessage id="appCookieConsentText" defaultMessage="By continuing your visit to this site, you accept the use of cookies for the purposes of the functioning of the proposed service and statistics. No personal data will be communicated without your prior consent." /></small>
					{' '}<Button size="outline-sm" variant="link" className="border-0" as={PredictiveLink} to={`/${locale}/data-protection`}><FormattedMessage id="appCookieConsentPrivacyLink" defaultMessage="See our privacy policy" /></Button>
				</CookieConsent>
			)}

			{isScrolling && userGroup && !getUserRolesForConsole().includes((user || {}).role) && !userGroup?.isFromParent && (
				((userGroup || {}).identifier === USER_GROUP_DEFAULT)
				? <CmsPanel slug="footer-slider" isTemplate />
				: <CmsPanel slug={`footer-slider-${userGroup.identifier}`} />
			)}
        </div>
    );
}

// <div className="trustpilot-widget" data-locale="fr-FR" data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="5ba10aceafa853000188761b" data-style-height="24px" data-style-width="100%" data-theme="light"> <a href="https://fr.trustpilot.com/review/directos.eu" target="_blank">Trustpilot</a> </div>

// <li className="py-2"><Link to={`/${props.locale}/page/about-us`}><FormattedMessage id="pageAboutUsLink" defaultMessage="Concept" /></Link></li>
// <li className="py-2"><Link to={`/${props.locale}/page/legal-mention`}><FormattedMessage id="pageLegalMentionLink" defaultMessage="Legal mention" /></Link></li>
// <li className="py-2"><Link to={`/${props.locale}/page/sales-conditions`}><FormattedMessage id="pageSalesConditionsLink" defaultMessage="Sales conditions" /></Link></li>
// <li className="py-2"><Link to={`/${props.locale}/page/data-protection`}><FormattedMessage id="pageDataProtectionLink" defaultMessage="Data protection" /></Link></li>

function mapStateToProps(store, props) {
    return {
        locale: store.intl.locale,
        user: getLoggedUser(store),
        userGroup: getUserGroup(store),
		isCookieConsentCustomActive: getConfig(store, 'cookie-consent-custom'),
    };
}

Footer.propTypes = {
    locale: PropTypes.string.isRequired,
    user: PropTypes.object,
    userGroup: PropTypes.object,
	isCookieConsentCustomActive: PropTypes.string,
};

export default connect(mapStateToProps)(Footer);
// export default Footer;
