import React from 'react';
import PropTypes from 'prop-types';
// import { CSSTransitionGroup } from 'react-transition-group';
import LazyLoad from 'react-lazyload';

// @lazyload({
//   height: 200,
//   once: true,
//   offset: 100
// })

function LazyLoader({ children, threshold = 200 }) {
    return (
        <LazyLoad height="100%" offset={threshold} once>
            {children}
        </LazyLoad>
    );
}

LazyLoader.propTypes = {
    children: PropTypes.object.isRequired,
    threshold: PropTypes.number,
};

export default LazyLoader;
