import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Container, Row, Col, ButtonGroup, Button } from 'react-bootstrap';

import VisibilitySensor from 'react-visibility-sensor';

import config from '../../../../config.js';
import { shuffle } from '../../../../util/array.js';

import { getReviews, getReviewSummaryRequest } from '../../ReviewActions.js';

import Icon from '../../../../components/Content/Icon.js';
import LazyLoader from '../../../../components/Content/LazyLoader.js';
import Slider from '../../../../components/Content/Slider.js';
import StarRating from './StarRating.js';

import ReviewListItem from '../list/ReviewListItem.js';

class ReviewSummary extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            reviews: [],
            type: 'review', // || 'question'
            isLoaded: true,
        };
    }

    // componentDidMount() {
	// 	this.init();
    // }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.intl.locale !== this.props.intl.locale) {
            this.init();
        }
    }

    handleVisibilityChange = isVisible => {
        if(isVisible && !this.state.isLoaded) {
            this.init();
        }
    };

    init = () => {
        !this.props.hideReviews && this.props.dispatch(getReviewSummaryRequest(this.props.intl.locale, (this.props.product || {})._id, this.state.type === 'question')).then(result => {
            this.setState({
                ...result,
                isLoaded: true,
            });
        });
    };

    toggleType = type => {
        this.setState({ type }, this.init);
    };

    handleViewReview = () => this.toggleType('review');

    handleViewQuestion = () => this.toggleType('question');

    render() {
        const reviewsCountPerSlide = this.props.appIsMobile ? 1 : 3;
        const reviewsGroups = [];
        const reviews = (this.state.reviews || []).concat(this.props.reviews).filter(review => { return this.state.type === 'review' ? review.score : !review.score; });
        const reviewsTemp = [...(this.props.isRandom ? shuffle(reviews) : reviews)];
        while(reviewsTemp.length > 0) {
            reviewsGroups.push(reviewsTemp.splice(0, reviewsCountPerSlide));
        }

        const colSize = 12 / reviewsCountPerSlide;

        const slides = reviewsGroups.map((reviews, indexGroup) => {
            return (
                <Row key={indexGroup}>
                    {reviews.map((review, indexReview) => (
                        <Col key={review._id} xs="12" md={colSize}>
                            <ReviewListItem review={review} index={(indexGroup * reviewsCountPerSlide) + (indexReview + 1)} />
                        </Col>
                    ))}
                </Row>
            );
        });

        return (
            // <VisibilitySensor onChange={this.handleVisibilityChange}>
                <Container className="py-1">
                    <div className="text-center">
                        {!this.props.hideLogo && (
                            <LazyLoader>
                                <picture>
                                    <source srcSet={`${config.url}assets/images/contact.webp`} type="image/webp" loading="lazy" />
                                    <img src={`${config.url}assets/images/contact.png`} alt="Contact" width="110" height="65" style={{ width: '110px' }} loading="lazy" />
                                </picture>
                                <br />
                            </LazyLoader>
                        )}
                        {!this.props.hideTitle && <span className="h2 d-block w-75 w-md-50 mx-auto mt-2 mb-2 px-1 px-md-0 text-primary text-center border-half-secondary"><FormattedMessage id="reviewsTitle" defaultMessage="Our customer reviews" /></span>}

                        {!this.props.hideSummary && (
                            <div className="text-center">
                                <StarRating displayTitle={!this.props.hideTitle} />
                            </div>
                        )}

                        {!this.props.hideReviews && (
                            <ButtonGroup size="lg">
                                <Button variant="link" size="lg" className={classnames(this.state.type !== 'review' && 'text-black-75')} onClick={this.handleViewReview}>{this.state.type === 'review' ? <FormattedMessage id="reviews" defaultMessage="Reviews" /> : <FormattedMessage id="reviewsRead" defaultMessage="Read reviews" />} {this.state.type !== 'review' && <Icon icon="arrow-right" />}</Button>
                                <Button variant="link" size="lg" className={classnames(this.state.type !== 'question' && 'text-black-75', 'border-left')} onClick={this.handleViewQuestion}>{this.state.type === 'question' ? <FormattedMessage id="reviewQuestions" defaultMessage="Questions" /> : <FormattedMessage id="reviewQuestionsRead" defaultMessage="Read questions" />} {this.state.type !== 'question' && <Icon icon="arrow-right" />}</Button>
                            </ButtonGroup>
                        )}
                    </div>
                    {this.state.isLoaded && !this.props.hideReviews && (
                        <div className="px-3 pb-2 px-md-0 slider-alt">
                            {!this.state.isLoaded && (
                                <div className="text-center">
                                    <Icon icon="spinner" spin size="3x" />
                                </div>
                            )}
                            {
                                this.state.type === 'review'
                                ? <Slider
									slides={slides.map(content => ({ content }))}
									disableLightbox
									disableControls={false}
									disableIndicators={false}
									interval={process.env.NODE_ENV === 'development' ? 99999999999 : 6000}
                                />
                                : (
                                    <div className="px-2 px-md-0">
                                        {reviews.map(review => (
                                            <Row key={review._id} className="mt-3 pt-3 border-top">
                                                <Col xs="12" md="4" className="mb-2">
                                                    <span className="h5 d-block mb-0 text-black-75"><FormattedMessage id="reviewQuestion" defaultMessage="Question" /></span>
                                                    <strong>{review.text}</strong>
                                                </Col>
                                                <Col className="text-end text-md-start">
                                                    <span className="h5 d-block mb-0 text-black-75"><FormattedMessage id="reviewAnswer" defaultMessage="Answer" /></span>
                                                    {review.answer}
                                                </Col>
                                            </Row>
                                        ))}
                                        <p className="text-center">
                                            <span className="d-block h3 my-3"><FormattedMessage id="reviewQuestionAsk" defaultMessage="Any question about our products or services?" /></span>
                                            <Button color="outline-secondary" size="sm" as={Link} to={`/${this.props.intl.locale}page/contact`}><FormattedMessage id="contactPageTitle" defaultMessage="Contact us" /></Button>
                                        </p>
                                    </div>
                                )
                            }
                        </div>
                    )}
                </Container>
            // </VisibilitySensor>

        );
    }
}

function mapStateToProps(store, props) {
	return {
		reviews: getReviews(store),
		appIsMobile: store.app.isMobile,
	};
}

ReviewSummary.defaultProps = {
    isRandom: false,
    hideTitle: false,
    hideLogo: false,
    hideSummary: false,
    hideReviews: false,
    product: null,
    appIsMobile: false,
};

ReviewSummary.propTypes = {
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
	reviews: PropTypes.arrayOf(PropTypes.object).isRequired,
    isRandom: PropTypes.bool,
    hideTitle: PropTypes.bool,
    hideLogo: PropTypes.bool,
    hideSummary: PropTypes.bool,
    hideReviews: PropTypes.bool,
    product: PropTypes.object,
    appIsMobile: PropTypes.bool,
};

export default connect(mapStateToProps)(injectIntl(ReviewSummary));
